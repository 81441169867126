import React,{useEffect} from 'react'
import { Box, Container, Grid, Typography } from "@mui/material";
import './PrivacyTerms.Scss'

const PrivacyPolicy = () => {
  
  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  useEffect(() => {
    topFunction();
  }, []);

  
  return (
    <div>
      <Box className="schedule-wrapper">
        <Container maxWidth="lg">
          <Box className="schedule-top">
            <h1>Privacy Policy for Neerob Restaurant</h1>

          </Box>
        </Container>
        <Container maxWidth="lg">
          <Grid container >
            <h2 style={{ color: "#2f75e6" }}>Last Updated: 10-10-2023</h2>

            <div>
              <p>At Neerob Restaurant, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you interact with our restaurant, website, or services.</p>
              <p>Please take a moment to read this Privacy Policy to understand how we handle your data.</p>
              <h2 style={{ color: "#2f75e6" }}>Information We Collect</h2>
              <p>We may collect the following types of information:</p>
              <ol>
                <li><strong>Personal Information:</strong> This may include your name, contact information (such as email address and phone number), and any other information you provide when making reservations or inquiries.</li>
                <li><strong>Transaction Data:</strong> We may collect information related to your purchases or reservations, including payment information and order history.</li>
                <li><strong>Device and Usage Information:</strong> We may automatically collect information about your device and how you interact with our website, such as your IP address, browser type, and pages visited.</li>
              </ol>
              <h2 style={{ color: "#2f75e6" }}>How We Use Your Information</h2>
              <p>We use your information for the following purposes:</p>
              <ol>
                <li><strong>Reservations and Orders:</strong> To facilitate reservations, process orders, and provide the requested services.</li>
                <li><strong>Communication:</strong> To respond to inquiries, send updates about your reservations or orders, and provide marketing materials if you've opted in to receive them.</li>
                <li><strong>Improvement:</strong> To enhance our services, website, and customer experience.</li>
                <li><strong>Legal Compliance:</strong> To comply with legal obligations and protect our rights and interests.</li>
              </ol>
              <h2 style={{ color: "#2f75e6" }}>Data Security</h2>
              <p>We take the security of your data seriously. We have implemented reasonable measures to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no data transmission over the internet is entirely secure, and we cannot guarantee the absolute security of your data.</p>
              <h2 style={{ color: "#2f75e6" }}>Sharing Your Information</h2>
              <p>We do not sell or rent your personal information to third parties. However, we may share your information with:</p>
              <ul>
                <li>Service providers and business partners who assist us in providing our services.</li>
                <li>Legal authorities when required by law or to protect our rights.</li>
                <li>In the event of a business transaction (e.g., merger, sale, or acquisition), your information may be transferred to the relevant third parties.</li>
              </ul>
              <h2 style={{ color: "#2f75e6" }}>Your Choices</h2>
              <p>You have the following choices regarding your information:</p>
              <ul>
                <li>You can access and update your personal information by contacting us.</li>
                <li>You can opt out of receiving marketing communications from us at any time.</li>
                <li>You can disable cookies through your browser settings, but this may affect your experience on our website.</li>
              </ul>
              <h2 style={{ color: "#2f75e6" }}>Changes to this Privacy Policy</h2>
              <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. The most recent version will be posted on our website with the date of the last update.</p>
              <h2 style={{ color: "#2f75e6" }}>Contact Us</h2>
              <p>If you have any questions, concerns, or requests related to this Privacy Policy or your personal information, please contact us:</p>
              <p>Email: neerobbronx@gmail.com</p>
              <p style={{ marginBottom: "100px" }}>Phone: (342) 342-3423</p>
            </div>

          </Grid>
        </Container>
      </Box>
    </div>
  )
}

export default PrivacyPolicy