import React,{useEffect, useState} from "react";
import "./App.scss";
// pages
import LandingPage from "./Pages/LandingPage";
import OurMenu from "./Pages/OurMenu";
import Schedule from "./Pages/Schedule";
import Packages from "./Pages/Packages";
import Reservation from "./Pages/Reservation";
import AboutUs from "./Pages/AboutUs";
import Catering from "./Pages/Catering";

// components
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import TopBar from "./Components/TopBar";
import { Routes, Route } from "react-router-dom";
import PrivacyPolicy from "./Pages/Policy/PrivacyPolicy";
import Terms from "./Pages/Policy/Terms";

const App = () => {

  return (
    <>
      <TopBar />
      <Navbar />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/our-menu" element={<OurMenu />} />
        <Route path="/schedule" element={<Schedule />} />
        <Route path="/packages" element={<Packages />} />
        <Route path="/reservation" element={<Reservation />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/catering" element={<Catering />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/Terms-and-conditions" element={<Terms />} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
