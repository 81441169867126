const timeSlots = [
    {
      id: 1,
      ftime: "8:00AM",
      btime: "08:00:00",
    },
    {
      id: 2,
      ftime: "8:30AM",
      btime: "08:30:00",
    },
    {
      id: 3,
      ftime: "9:00AM",
      btime: "09:00:00",
    },
    {
      id: 4,
      ftime: "9:30AM",
      btime: "09:30:00",
    },
    {
      id: 5,
      ftime: "10:00AM",
      btime: "10:00:00",
    },
    {
      id: 6,
      ftime: "10:30AM",
      btime: "10:30:00",
    },
    {
      id: 7,
      ftime: "11:00AM",
      btime: "11:00:00",
    },
    {
      id: 8,
      ftime: "11:30AM",
      btime: "11:30:00",
    },
    {
      id: 9,
      ftime: "12:00PM",
      btime: "12:00:00",
    },
    {
      id: 10,
      ftime: "12:30PM",
      btime: "12:30:00",
    },
    {
      id: 11,
      ftime: "1:00PM",
      btime: "13:00:00",
    },
    {
      id: 12,
      ftime: "1:30PM",
      btime: "13:30:00",
    },
    {
      id: 13,
      ftime: "2:00PM",
      btime: "14:00:00",
    },
    {
      id: 14,
      ftime: "2:30PM",
      btime: "14:30:00",
    },
    {
      id: 15,
      ftime: "3:00PM",
      btime: "15:00:00",
    },
    {
      id: 16,
      ftime: "3:30PM",
      btime: "15:30:00",
    },
    {
      id: 17,
      ftime: "4:00PM",
      btime: "16:00:00",
    },
    {
      id: 18,
      ftime: "4:30PM",
      btime: "16:30:00",
    },
    {
      id: 19,
      ftime: "5:00PM",
      btime: "17:00:00",
    },
    {
      id: 20,
      ftime: "5:30PM",
      btime: "17:30:00",
    },
    {
      id: 21,
      ftime: "6:00PM",
      btime: "18:00:00",
    },
    {
      id: 22,
      ftime: "6:30PM",
      btime: "18:30:00",
    },
    {
      id: 23,
      ftime: "7:00PM",
      btime: "19:00:00",
    },
    {
      id: 24,
      ftime: "7:30PM",
      btime: "19:30:00",
    },
    {
      id: 25,
      ftime: "8:00PM",
      btime: "20:00:00",
    },
    {
      id: 26,
      ftime: "8:30PM",
      btime: "20:30:00",
    },
    {
      id: 27,
      ftime: "9:00PM",
      btime: "21:00:00",
    },
    {
      id: 28,
      ftime: "9:30PM",
      btime: "21:30:00",
    },
    {
      id: 29,
      ftime: "10:00PM",
      btime: "22:00:00",
    },
    {
      id: 30,
      ftime: "10:30PM",
      btime: "22:30:00",
    },
  ];
  
  export default timeSlots;
  