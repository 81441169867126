import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { ReactComponent as Phone } from "./assets/mob-icon.svg";
import { ReactComponent as Email } from "./assets/email-icon.svg";
import { formatPhoneNumber } from "../../Utils/Helpers";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import { Link } from "react-router-dom";
import "./TopBar.scss";
import axios from "axios";

const TopBar = () => {
  const [company, setCompany] = useState([]);
  const baseUrl = process.env.REACT_APP_BASE_API_URL;
  const headers = {
    Authorization: process.env.REACT_APP_API_KEY,
  };

  const fetchCompany = async () => {
    try {
      await axios
        .get(`${baseUrl}/api/company/`, { headers })
        .then((response) => {
          let _company = response?.data?.results?.[0];
          setCompany(_company);
        })
        .catch((error) => {
          console.log(error.message);
        });
    } finally {
    }
  };

  useEffect(() => {
    fetchCompany().then((r) => {});
  }, []);

  return (
    <>
      <Box className="top-bar">
        <a
          href={`tel:${company?.phone}`}
          style={{ marginRight: "10px", textDecoration: "none" }}
        >
          <Phone />
          &nbsp;&nbsp;
          <p className="info-company">
            {company?.phone ? formatPhoneNumber(company?.phone) : ""}
          </p>
        </a>
        <a
          href={`mailto:${company.email}`}
          style={{ marginRight: "10px", textDecoration: "none" }}
        >
          <Email />
          &nbsp;&nbsp;
          <p className="info-company">{company.email}</p>
        </a>
        <Link style={{ marginRight: "5%", textDecoration: "none" }}>
          <AccessTimeFilledIcon style={{ fontSize: "20px" }} />
          <p>&nbsp;08:00 am - 10:30 pm</p>
        </Link>
      </Box>
    </>
  );
};

export default TopBar;
