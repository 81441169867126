import React,{useState} from "react";
import "./Navbar.scss";
import { Container, Box, Button } from "@mui/material";
import { Link } from "react-router-dom";
import CustomButton from "../CustomButton";
import { ReactComponent as MenuIcon } from "../../assets/menu-icon.svg";
import { ReactComponent as CrossIcon } from "../../assets/cross-icon.svg";
import Logo from "./assets/logo.png";
import { ReactComponent as DropDownIcon } from "./assets/dorpdownIcon.svg";
import DoorDashImg from "./assets/image 2.png";
import semles from "./assets/image 3.png";
import uber from "./assets/image 4.png";
import swallow from "./assets/swallow.png";
import Drawer from "@mui/material/Drawer";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [isDropDown, setIsDropDown] = useState(false);
  const navigate = useNavigate();
  const desktop = useMediaQuery("(min-width: 1000px)");
  const handleDrawer = () => {
    setOpen(!open);
  };
  const handleReservationBtn = () => {
    setOpen(false);
    navigate("/reservation");
  };

  const list = () => (
    <Box sx={{ width: 390 }} role="presentation">
      <Box className="mobile-nav">
        <Box className="mobile-nav-top">
          <Box className="mobile-logo-wrapper">
            <img src={Logo} alt="logo" />
            {/* <img src={subTitleLogo} alt="logo" /> */}
            <img src={swallow} alt="logo" className="swallow" />
          </Box>
          <CrossIcon onClick={() => handleDrawer()} />
        </Box>

        <Box className="mobile-nav-items">
          <ul>
            <li>
              <Link to="/" onClick={() => handleDrawer()}>
                Home
              </Link>
            </li>
            <li>
              <a href="our-menu">Menu</a>
            </li>
            <li>
              <Link to="/packages" onClick={() => handleDrawer()}>
                Specials
              </Link>
            </li>
            <li>
              <Link to="/catering" onClick={() => handleDrawer()}>
                Catering
              </Link>
            </li>
            <li>
              <Link to="/about-us" onClick={() => handleDrawer()}>
                About
              </Link>
            </li>
          </ul>
        </Box>
        <Box className="mobile-nav-btns">
          <CustomButton
            value="Reservation"
            outline
            onClick={handleReservationBtn}
          />
          <CustomButton
            onClick={() => setIsDropDown((prev) => !prev)}
            value={"order now"}
          />
          {isDropDown && (
            <ul className="mobile-order-menu">
              <a
                href="https://www.doordash.com/store/neerob-restaurant-the-bronx-587008/"
                target="_blank"
                rel="noreferrer"
              >
                <li>
                  <img src={DoorDashImg} alt="" srcSet="" />
                  Door Dash
                </li>
              </a>
              {/* <a
                href="https://www.seamless.com/menu/neerob-restaurant-2096-starling-ave-bronx/2792462"
                target="_blank"
                rel="noreferrer"
              >
                <li>
                  <img src={semles} alt="" srcSet="" />
                  Seamless
                </li>
              </a>
              <a
                href="https://www.ubereats.com/store/neerob-restaurant-and-halal-chinese/3WY50YwSX2qh0t10iQHaWA"
                target="_blank"
                rel="noreferrer"
              >
                <li>
                  <img src={uber} alt="" srcSet="" />
                  Uber Eats
                </li>
              </a> */}
            </ul>
          )}
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <Box className="navbar-wrapper-main">
        <Container maxWidth="lg">
          <Box className="navbar-wrapper">
            <div
              style={{
                display: "flex",
                alignItems: "baseline",
                marginTop: "5px",
              }}
            >
              <Box className="left">
              <a href="/">
                <img src={Logo} alt="logo" />
                {/* <img src={subTitleLogo} alt="logo" /> */}
                <img src={swallow} alt="logo" className="swallow" />
              </a>
              </Box>
            </div>

            <Box className="menu-icon">
              <MenuIcon
                onClick={() => {
                  handleDrawer();
                }}
              />
            </Box>

            <Box className="center">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <a href="our-menu">Menu</a>
                </li>
                <li>
                  <Link to="/packages">Specials</Link>
                </li>
                <li>
                  <Link to="/catering">Catering</Link>
                </li>
                <li>
                  <Link to="/about-us">About</Link>
                </li>
              </ul>
            </Box>
            <Box className="right">
              <CustomButton
                value="Reservation"
                outline
                onClick={handleReservationBtn}
                style={{ marginRight: "10px" }}
              />
              {/* <CustomButton value="order now" /> */}
              <ClickAwayListener
                onClickAway={() => {
                  if (desktop) {
                    setIsDropDown(false);
                  }
                }}
              >
                <div>
                  <Button
                    variant="contained"
                    className="order-btn"
                    onClick={() => setIsDropDown(!isDropDown)}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Order Now <DropDownIcon />
                  </Button>
                  <ul className={isDropDown ? "btn-dropdown" : "dropdown-none"}>
                    <a
                      href="https://www.doordash.com/store/neerob-restaurant-the-bronx-587008/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <li>
                        <img src={DoorDashImg} alt="" srcSet="" />
                        Door Dash
                      </li>
                    </a>
                    {/* <a
                      href="https://www.seamless.com/menu/neerob-restaurant-2096-starling-ave-bronx/2792462"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <li>
                        <img src={semles} alt="" srcSet="" />
                        Seamless
                      </li>
                    </a>
                    <a
                      href="https://www.ubereats.com/store/neerob-restaurant-and-halal-chinese/3WY50YwSX2qh0t10iQHaWA"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <li>
                        <img src={uber} alt="" srcSet="" />
                        Uber Eats
                      </li>
                    </a> */}
                  </ul>
                </div>
              </ClickAwayListener>
            </Box>
          </Box>
        </Container>
      </Box>
      <Drawer open={open} onClose={() => handleDrawer()}>
        {list()}
      </Drawer>
    </>
  );
};

export default Navbar;
