import React, { useEffect, useState } from "react";
import { Box, Container, Grid, TextField } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar from "@mui/material/Snackbar";
import Select from "@mui/material/Select";
import timeSlots from "./TimeSlots";
import MenuItem from "@mui/material/MenuItem";
import Alert from "@mui/material/Alert";
import FormLabel from "@mui/material/FormLabel";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { addYears } from "date-fns";

import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "./Reservation.scss";

const Reservation = () => {
  const baseUrl = process.env.REACT_APP_BASE_API_URL;
  const header = process.env.REACT_APP_API_KEY;

  const headers = {
    Authorization: process.env.REACT_APP_API_KEY,
  };

  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [guests, setGuests] = useState(null);
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [duration, setDuration] = useState(null);
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedService, setSelectedService] = useState("reservation");
  const [isDelivery, setIsDelivery] = useState(false);
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [company, setCompany] = useState([]);
  const [fieldStatus, setFieldStatus] = useState(false);
  const [isEmptyFields, setIsEmptyFields] = useState(false);
  const [formattedMobile, setFormattedMobile] = useState("");

  const [locationLoading, setLocationLoading] = useState(false);
  const [locationData, setLocationData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const [errorToast, setErrorToast] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(false);

  useEffect(() => {
    fetchCompany().then((r) => {});
  }, []);

  useEffect(() => {
    getLocation();
  }, []);

  const getLocation = () => {
    setLocationLoading(true);

    axios
      .get(`${baseUrl}/api/company/location/`, { headers })
      .then((res) => {
        setLocationLoading(false);
        setLocationData(res.data.results);
      })
      .catch((err) => {
        console.log(err);
        setLocationLoading(false);
      });
  };

  const fetchCompany = async () => {
    try {
      await axios
        .get(`${baseUrl}/api/company/`, { headers })
        .then((response) => {
          let _company = response?.data?.results?.[0];
          setCompany(_company);
        })
        .catch((error) => {
          console.log(error.message);
        });
    } finally {
    }
  };
  const handleDeliveryChange = (event) => {
    setIsDelivery(event.target.value === "delivery");
  };
  const handleServiceChange = (event) => {
    setSelectedService(event.target.value);
    setDuration(null);
  };

  const handleSubmit = () => {
    if (
      !name ||
      !email ||
      !mobile ||
      !guests ||
      !date ||
      !time ||
      !selectedLocation
    ) {
      setIsEmptyFields(true);
      setFieldStatus(true);
    } else if (mobile.length !== 15) {
      setErrorToast("Please enter a valid mobile number");
    } else {
      submitData();
    }
  };

  const submitData = async () => {
    const formattedDate = date.toISOString().split("T")[0];
    setLoading(true);

    const data = {
      location: selectedLocation,
      num_of_guests: Number(guests),
      event_date: formattedDate,
      start_time: time,
      notes: details,
      event_type: selectedService,
      duration_minutes: Number(duration),
      is_delivery: isDelivery,
      ...(isDelivery ? { delivery_address: deliveryAddress } : {}),
      contacts: [
        {
          first_name: name,
          phone_number: mobile,
          email: email,
        },
      ],
    };
    axios
      .post(`${process.env.REACT_APP_BASE_API_URL}/api/event/`, data, {
        headers: {
          Authorization: process.env.REACT_APP_API_KEY,
        },
      })
      .then((response) => {
        setOpen(true);
        setLoading(false);
        setDisableSubmit(true);

        setInterval(() => {
          window.location.reload();
        }, 5000);
      })
      .catch((error) => {
        console.log(error.response.message);
        setLoading(false);
        error.response.data.message.map((items) => {
          setErrorToast(
            items.event_date
              ? items.event_date
              : items.email
              ? items.email
              : items.delivery_address
              ? items.delivery_address
              : "Something went wrong"
          );
        });
      });
  };

  const formatPhoneNumber = (input) => {
    const digitsOnly = input.replace(/\D/g, "");
    let formatted = "";
    if (digitsOnly.length >= 3) {
      formatted = `(${digitsOnly.slice(0, 3)}`;
      if (digitsOnly.length > 3) {
        formatted += `) ${digitsOnly.slice(3, 6)}`;
        if (digitsOnly.length > 6) {
          formatted += `-${digitsOnly.slice(6, 10)}`;
        }
      }
    } else {
      formatted = digitsOnly;
    }
    return formatted;
  };

  const handleMobileChange = (event) => {
    const input = event.target.value;
    const formatted = formatPhoneNumber(input);
    setFormattedMobile(formatted);
    setMobile(input);
  };

  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  useEffect(() => {
    topFunction();
  }, []);

  const handleWheel = (e) => {
    e.preventDefault();
  };

  const handleKeyDowns = (e) => {
    const invalidKeys = ["ArrowUp", "ArrowDown"];
    if (invalidKeys.includes(e.key)) {
      e.preventDefault();
    }
  };


  useEffect(() => {
    topFunction();
  }, []);

  const handleScroll = (e) => {
    if (document.activeElement.tagName === "INPUT") {
      e.preventDefault();
    }
  };

  const handleKeyDown = (e) => {
    if (
      document.activeElement.tagName === "INPUT" &&
      (e.key === "ArrowUp" || e.key === "ArrowDown")
    ) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    document.addEventListener("wheel", handleScroll, { passive: false });
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("wheel", handleScroll);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <>
      <Box className="reservation-wrapper">
        <Container maxWidth="lg">
          <Box className="reservation-top">
            <h1>Reservation</h1>
            <p>
              Secure Your Table in Moments: Experience Culinary Excellence with
              Our Reservation Services. From Intimate Dinners to Memorable
              Gatherings, Your Culinary Journey Awaits!"
            </p>
          </Box>
        </Container>
        <Container maxWidth="lg">
          <Grid container spacing={2} mb={5}>
            <Grid item xs={12} md={5}>
              <Box className="reservation-left">
                <Box className="get-in-touch">
                  <h2>Get in touch</h2>

                  <Box className="get-in-touch-info">
                    <h4>Phone</h4>
                    <p>
                      {company?.phone ? formatPhoneNumber(company?.phone) : ""}
                    </p>
                  </Box>
                  <Box className="get-in-touch-info">
                    <h4>Email</h4>
                    <p> {company.email}</p>
                  </Box>
                  {/* <Box className="get-in-touch-info">
                    <h4>Social</h4>
                    <p>@sample</p>
                  </Box> */}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={7}>
              <Box className="reservation-right">
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    What are you contracting for?
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={selectedService}
                    onChange={handleServiceChange}
                  >
                    <FormControlLabel
                      value="catering"
                      control={<Radio />}
                      label="Catering"
                    />
                    <FormControlLabel
                      value="reservation"
                      control={<Radio />}
                      label="Reservation"
                    />
                  </RadioGroup>
                </FormControl>

                {selectedService === "catering" && (
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Is this a delivery or Pickup?
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={isDelivery ? "delivery" : "pickup"}
                      onChange={handleDeliveryChange}
                    >
                      <FormControlLabel
                        value="delivery"
                        control={<Radio />}
                        label="Delivery"
                      />
                      <FormControlLabel
                        value="pickup"
                        control={<Radio />}
                        label="Pickup"
                      />
                    </RadioGroup>
                  </FormControl>
                )}

                {isDelivery === true && (
                  <Box className="field-wrapper">
                    <label htmlFor="">
                      Delivery address <span style={{ color: "red" }}>*</span>
                    </label>
                    <TextField
                      style={{
                        borderRadius: "7px",
                        border:
                          !deliveryAddress && fieldStatus === true
                            ? "1px solid red"
                            : null,
                      }}
                      value={deliveryAddress}
                      type="text"
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      onChange={(event) =>
                        setDeliveryAddress(event.target.value)
                      }
                    />
                  </Box>
                )}

                {locationLoading ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Box className="field-wrapper">
                    <label htmlFor="">
                      Location <span style={{ color: "red" }}>*</span>
                    </label>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedLocation}
                        onChange={(e) => setSelectedLocation(e.target.value)}
                        style={{
                          borderRadius: "7px",
                          border:
                            !selectedLocation && fieldStatus === true
                              ? "1px solid red"
                              : null,
                        }}
                      >
                        {locationData.map((items, i) => {
                          return (
                            <MenuItem key={i} value={items.id}>
                              {items.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                )}

                <Box className="field-wrapper">
                  <label htmlFor="">
                    Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    value={name}
                    type="text"
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    onChange={(event) => setName(event.target.value)}
                    style={{
                      borderRadius: "7px",
                      border:
                        !name && fieldStatus === true ? "1px solid red" : null,
                    }}
                  />
                </Box>
                <Box className="field-wrapper">
                  <label htmlFor="">
                    Email Address<span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    type="email"
                    value={email}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    onChange={(event) => setEmail(event.target.value)}
                    style={{
                      borderRadius: "7px",
                      border:
                        !email && fieldStatus === true ? "1px solid red" : null,
                    }}
                  />
                </Box>
                <Box className="field-wrapper">
                  <label htmlFor="">
                    Phone Number<span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    type="text"
                    value={formattedMobile}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    onChange={handleMobileChange}
                    style={{
                      borderRadius: "7px",
                      border:
                        !mobile && fieldStatus === true
                          ? "1px solid red"
                          : null,
                    }}
                  />
                </Box>
                <Box className="field-wrapper">
            <label htmlFor="">
              Number of people attending<span style={{ color: "red" }}>*</span>
            </label>
            <TextField
              type="number"
              id="outlined-basic"
              value={guests}
              label=""
              variant="outlined"
              onChange={(e) => setGuests(e.target.value)}
              InputProps={{
                onWheel: handleWheel,
                onKeyDown: handleKeyDowns,
              }}
              style={{
                borderRadius: "5px",
                border:
                  !guests && fieldStatus === true ? "1px solid red" : null,
              }}
            />
          </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Box className="field-wrapper">
                      <label htmlFor="">
                        Date<span style={{ color: "red" }}>*</span>
                      </label>
                      <DatePicker
                        format="MM-dd-y"
                        onChange={setDate}
                        value={date}
                        className={`datePickers ${
                          !date && fieldStatus === true ? "invalid" : ""
                        }`}
                        minDate={new Date()}
                        maxDate={addYears(new Date(), 1)}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <Box className="field-wrapper">
                      <label htmlFor="">
                        Time<span style={{ color: "red" }}>*</span>
                      </label>
                      <FormControl>
                        <Select
                          style={{
                            borderRadius: "5px",
                            border:
                              !time && fieldStatus === true
                                ? "1px solid red"
                                : null,
                          }}
                          onChange={(e) => setTime(e.target.value)}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          {timeSlots.map((items, i) => {
                            return (
                              <MenuItem key={i} value={items.btime}>
                                {items.ftime}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>

                  {selectedService=="reservation" && (           
                  <Grid item xs={12} md={3}>
                    <Box className="field-wrapper">
                      <label htmlFor="">
                        Event Duration<span style={{ color: "red" }}>*</span>
                      </label>
                      <FormControl>
                        <Select
                          style={{
                            borderRadius: "5px",
                            border:
                              !duration && fieldStatus === true
                                ? "1px solid red"
                                : null,
                          }}
                          onChange={(e) => setDuration(e.target.value)}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem value={60}>1 Hour</MenuItem>
                          <MenuItem value={120}>2 Hours</MenuItem>
                          <MenuItem value={180}>3 Hours</MenuItem>
                          <MenuItem value={240}>4 Hours</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  )}
                </Grid>         
                <Box className="field-wrapper">
                  <label htmlFor="">Details</label>
                  <TextField
                    id="outlined-basic"
                    label=""
                    value={details}
                    multiline
                    rows={4}
                    variant="outlined"
                    onChange={(event) => setDetails(event.target.value)}
                    style={{
                      borderRadius: "5px",
                      // border:
                      //   !details && fieldStatus === true
                      //     ? "1px solid red"
                      //     : null,
                    }}
                  />
                </Box>

                <Box className="btn-wrapper">
                  <LoadingButton
                    variant="outlined"
                    onClick={() => handleSubmit()}
                    loading={loading}
                    disabled={disableSubmit}
                  >
                    Submit
                  </LoadingButton>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={open}
            onClose={() => setOpen(false)}
            key={"top" + "right"}
            autoHideDuration={5000}
          >
            <Alert
              variant="filled"
              onClose={() => setOpen(false)}
              severity="success"
              sx={{ width: "100%" }}
            >
              Your request is submitted. Please check your email for further details
            </Alert>
          </Snackbar>

          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={isEmptyFields}
            autoHideDuration={5000}
            onClose={() => setIsEmptyFields(false)}
          >
            <Alert
              variant="filled"
              onClose={() => setIsEmptyFields(false)}
              severity="error"
              sx={{ width: "100%" }}
            >
              Please fill out all the fields
            </Alert>
          </Snackbar>

          {errorToast && (
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              open={Boolean(errorToast)}
              onClose={() => setErrorToast("")}
            >
              <Alert
                variant="filled"
                onClose={() => setErrorToast("")}
                severity="error"
                sx={{ width: "100%" }}
              >
                {errorToast}
              </Alert>
            </Snackbar>
          )}
        </Container>
      </Box>
    </>
  );
};

export default Reservation;
