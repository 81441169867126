import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./OurMenu.scss";
import "./Components/MenuTemplate/MenuTemplate.scss";
import menu from "./PopularItems";
import { Box, Container, Grid } from "@mui/material";
import { ReactComponent as RightArrow } from "../../assets/right-arrow.svg";
import MenuTemplate from "./Components/MenuTemplate";
import dailyMenu from "../../assets/OurMenu/daily-menu.png";
import seasonalMenu from "../../assets/OurMenu/seasonal menu.png";
import cateringMenu from "../../assets/OurMenu/catering-menu.png";
import specialMenu from "../../assets/OurMenu/special-menu.png";

const OurMenu = () => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [code, setCode] = useState("");

  useEffect(() => {
    topFunction();
    getMenuType();
  }, []);

  useEffect(() => {
    if (code) {
      setActiveMenu(code);
    }
  }, [code]);

  function getMenuType() {
    const url = `${window.location.href}`;
    const part = url.split("?");
    const path = part[1];
    setCode(path);
  }

  const handleMenuItemClick = (menuItem) => {
    setActiveMenu(menuItem);
  };

  const currentMenu = activeMenu
    ? menu.find((item) => item.menuItem === activeMenu)
    : null;

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  // scroll to top
  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  return (
    <>
      <Box className="our-menu-wrapper">
        <div className="menu-page-main-item menu-row">
          {menu.map((items) => (
            <p
              key={items.id}
              onClick={() => handleMenuItemClick(items.menuItem)}
              className={
                items.menuItem === activeMenu ? "menu-item-active" : "menu-item"
              }
            >
              &nbsp;{items.menuItem}&nbsp;
            </p>
          ))}
        </div>
        <Box className="menu-top">
          {currentMenu ? (
            <>
              <h1>{currentMenu.menuItem}</h1>
              <p
                style={{
                  textAlign: "center",
                  padding: "0px 50px",
                  marginBottom: "40px",
                }}
              >
                {currentMenu.heading}
              </p>

              <div className="menu-row">
                {currentMenu.dishes.map((items) => {
                  return (
                    <div key={items.id} className="menu-col-4">
                      <div className="menu-item-card">
                        <div className="menu-col-8">
                          <div>
                            <Grid
                              container
                              rowSpacing={0}
                              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            >
                              <Grid item xs={6}>
                                <h5
                                  style={{
                                    color: "#2f75e6",
                                    marginTop: "5px",
                                    marginBottom: "0px",
                                  }}
                                >
                                  {items.name}
                                </h5>
                              </Grid>
                              <Grid item xs={6}>
                                <div className="price-unit-container">
                                  {items.unit ? (
                                    <p className="unit-tag">{items.unit}</p>
                                  ) : null}
                                  {items.dollar ? (
                                    <p className="price-tag">${items.dollar}</p>
                                  ) : null}
                                </div>
                              </Grid>
                            </Grid>
                          </div>

                          {items.description ? (
                            <p
                              className="description-truncate"
                              style={{
                                color: "#2f75e6",
                                fontSize: "13px",
                                marginTop: "0px",
                              }}
                            >
                              {/* {truncateText(items.description, 75)} */}
                              {items.description}
                            </p>
                          ) : null}
                        </div>
                        <div className="menu-col-5">
                          <img
                            loading="lazy"
                            src={items.images}
                            className="img-fluid"
                            alt={items.name}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : null}
        </Box>
        {activeMenu === null ? (
          <>
            <Box className="menu-template-wrapper">
              <Container maxWidth="lg">
                <Box className="menu-template-title">
                  <h1
                    style={{
                      fontSize: "20px",
                      fontWeight: 350,
                      marginBottom: "20px",
                      textAlign: "center",
                    }}
                  >
                    Select a menu option to view dishes
                  </h1>
                  <h1>Popular</h1>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Box className="menu-template-item-left">
                      <img
                        alt="menu-item-image"
                        loading="lazy"
                        src={dailyMenu}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box className="menu-template-item-right">
                      <Box className="list-item">
                        <h3>
                          <span>Biryani (Beef, Chicken, Shrimp)</span>
                          {/* <span>$8.50</span>{" "} */}
                        </h3>
                        {/* <p className="size">
                    <span className="first">4oz</span> <span>8oz</span>
                  </p> */}
                        <p>
                          A flavorful and aromatic one-pot rice dish with
                          chicken or beef or shrimp or veges, cooked with
                          fragrant spices and herbs.
                        </p>
                      </Box>
                      <Box className="list-item">
                        <h3>
                          <span>Curry (Beef, Chicken, Hilsha)</span>
                          {/* <span>$8.50</span>{" "} */}
                        </h3>
                        {/* <p className="size">
                    <span className="first">4oz</span> <span>8oz</span>
                  </p> */}
                        <p>
                          Meat or Fish or Veges cooked in a rich and flavorful
                          curry sauce, perfect for enjoying with rice or bread.
                        </p>
                      </Box>
                      <Box className="list-item">
                        <h3>
                          <span>Puri</span>
                          {/* <span>$8.50</span>{" "} */}
                        </h3>
                        {/* <p className="size">
                    <span className="first">4oz</span> <span>8oz</span>
                  </p> */}
                        <p>
                          One of many appealing appetizers that is favorite with
                          our customers. A puri is deep-fried bread made from
                          wheat flour and lentils, offering a flavorful and
                          crispy snack or appetizer.
                        </p>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Box>

            <Box className="menu-template-wrapper">
              <Container maxWidth="lg">
                <Box className="menu-template-title">
                  <h1>Seasonal</h1>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Box className="menu-template-item-left">
                      <img
                        alt="menu-item-image"
                        loading="lazy"
                        src={seasonalMenu}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box className="menu-template-item-right">
                      <Box className="list-item">
                        <h3>
                          <span>Haleem (Beef)</span>
                          {/* <span>$8.50</span>{" "} */}
                        </h3>
                        {/* <p className="size">
                    <span className="first">4oz</span> <span>8oz</span>
                  </p> */}
                        <p>
                          A thick and hearty stew made from beef and grains,
                          cooked with a variety of spices and herbs, creating a
                          nourishing and flavorful meal.
                        </p>
                      </Box>
                      <Box className="list-item">
                        <h3>
                          <span>Lal Saag Bhaja</span>
                          {/* <span>$8.50</span>{" "} */}
                        </h3>
                        {/* <p className="size">
                    <span className="first">4oz</span> <span>8oz</span>
                  </p> */}
                        <p>
                          Dish made from red amaranth leaves, cooked with spices
                          to create a delicious and colorful leafy greens side
                          dish.
                        </p>
                      </Box>
                      <Box className="list-item">
                        <h3>
                          <span>Custom Order</span>
                          {/* <span>$8.50</span>{" "} */}
                        </h3>
                        {/* <p className="size">
                    <span className="first">4oz</span> <span>8oz</span>
                  </p> */}
                        <p>We will customize any order, please contact us.</p>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Box>

            <Box className="menu-template-wrapper">
              <Container maxWidth="lg">
                <Box className="menu-template-title">
                  <h1>Catering</h1>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Box className="menu-template-item-left">
                      <img
                        alt="menu-item-image"
                        loading="lazy"
                        src={cateringMenu}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box className="menu-template-item-right">
                      <Box className="list-item">
                        <h3>
                          <Link
                            to="/catering"
                            style={{
                              textDecoration: "none",
                              cursor: "pointer",
                              color: "#2F75E6",
                            }}
                          >
                            <span>
                              We cater to all events. <br /> Chat with us and we
                              will make sure <br /> you enjoy our food
                              <RightArrow
                                className="arrow-animation"
                                style={{ height: "15px" }}
                              />
                            </span>
                          </Link>{" "}
                          {/* <span>$8.50</span>{" "} */}
                        </h3>
                        {/* <p className="size">
                    <span className="first">4oz</span> <span>8oz</span>
                  </p> */}
                        {/* <p>
                          We cater to all events. Chat with us and we will make
                          sure you enjoy our food. (send the user to the
                          catering tab)
                        </p> */}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Box>

            <Box className="menu-template-wrapper">
              <Container maxWidth="lg">
                <Box className="menu-template-title">
                  <h1>Special Menu</h1>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Box className="menu-template-item-left">
                      <img
                        alt="menu-item-image"
                        loading="lazy"
                        src={specialMenu}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {/* <Box className="menu-template-item-right">
                      <Box className="list-item">
                        <h3>
                          <span>Getting back to you ...</span>
                          <span>$8.50</span>{" "}
                        </h3>
                        <p className="size">
                    <span className="first">4oz</span> <span>8oz</span>
                  </p>
                        <p>Getting back to you ...</p>
                      </Box>
                    </Box> */}
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </>
        ) : null}
        )
      </Box>
    </>
  );
};

export default OurMenu;
