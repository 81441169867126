import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import axios from "axios";
import Card from "./Components/Card";
import "./Schedule.scss";
import SayHello from "../../Components/SayHello";

const Schedule = () => {
  const [companyInfo, setCompanyInfo] = useState()

  const getCompany = async () => {
    axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/company/`, {
      headers: {
        "Authorization": process.env.REACT_APP_API_KEY,
      }
    }).then((response) => {
      setCompanyInfo(response?.data?.results[0])
    }).catch((error) => {
      console.log(error);
    })
  }

  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  useEffect(() => {
    topFunction();
  }, []);

  useEffect(() => {
    getCompany()
  }, [])

  return (
    <>
      <Box className="schedule-wrapper">
        <Container maxWidth="lg">
          <Box className="schedule-top">
            <h1>Schedule</h1>
           
          </Box>
        </Container>
        <Container maxWidth="lg">
          <Grid container spacing={4} className="schedule-card-wrapper">
            <Grid item xs={12} sm={6} md={3}>
              <Card day="Monday" address={companyInfo?.address} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card day="Tuesday" address={companyInfo?.address} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card day="Wednesday" address={companyInfo?.address} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card day="Thursday" address={companyInfo?.address} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card day="Friday" address={companyInfo?.address} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card day="Saturday" address={companyInfo?.address} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card day="Sunday" address={companyInfo?.address} />
            </Grid>
          </Grid>
        </Container>

        <SayHello/>
      </Box>
    </>
  );
};

export default Schedule;
