import React, { useState, useEffect } from "react";
import { Box, Container, Grid, Typography, TextField } from "@mui/material";
import cateringImg from "./assets/catering_1.png";
import cateringImg2 from "./assets/catering_2.png";

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import img1 from "./assets/1.png";
import img2 from "./assets/2.png";
import img3 from "./assets/3.png";
import img4 from "./assets/4.png";
import img5 from "./assets/5.png";
import img6 from "./assets/6.png";
import img7 from "./assets/7.png";
import img9 from "./assets/9.jpg";
import cateringImgVector from "./assets/catering_vector_1.png";
import cateringImgVector2 from "./assets/catering_vector_2.png";
import cateringImgVector3 from "./assets/catering_vector_3.png";
import cateringImgVector4 from "./assets/catering_vector_4.png";

import "./Catering.scss";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import DatePicker from "react-date-picker";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
import timeSlots from "./TimeSlots";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { addYears } from "date-fns";

const Catering = () => {
  const baseUrl = process.env.REACT_APP_BASE_API_URL;
  const headers = {
    Authorization: process.env.REACT_APP_API_KEY,
  };
  const itemData = [
    {
      img: img1,
      title: "Bed",
    },
    {
      img: img2,
      title: "Kitchen",
    },
    {
      img: img3,
      title: "Sink",
    },
    {
      img: img4,
      title: "Books",
    },
    {
      img: img5,
      title: "Chairs",
    },
    {
      img: img6,
      title: "Candle",
    },
    {
      img: img7,
      title: "Laptop",
    },
    {
      img: img9,
      title: "Laptop",
    },
  ];

  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [guests, setGuests] = useState(null);
  const [time, setTime] = useState(null);
  const [duration, setDuration] = useState(null);
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isDelivery, setIsDelivery] = useState(false);
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [date, setDate] = useState(null);

  const [open, setOpen] = useState(false);
  const [isEmptyFields, setIsEmptyFields] = useState(false);
  const [fieldStatus, setFieldStatus] = useState(false);
  const [formattedMobile, setFormattedMobile] = useState("");
  const [errorToast, setErrorToast] = useState("");

  const [locationLoading, setLocationLoading] = useState(false);
  const [locationData, setLocationData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(false);

  useEffect(() => {
    getLocation();
  }, []);

  const handleDeliveryChange = (event) => {
    setIsDelivery(event.target.value === "delivery");
  };

  const getLocation = () => {
    setLocationLoading(true);

    axios
      .get(`${baseUrl}/api/company/location/`, { headers })
      .then((res) => {
        setLocationLoading(false);
        setLocationData(res.data.results);
      })
      .catch((err) => {
        console.log(err);
        setLocationLoading(false);
      });
  };

  const handleSubmit = () => {
    if (
      !name ||
      !email ||
      !mobile ||
      !guests ||
      !date ||
      !time ||
      !selectedLocation
    ) {
      setIsEmptyFields(true);
      setFieldStatus(true);
    } else if (mobile.length !== 15) {
      setErrorToast("Please enter a valid mobile number");
    } else {
      submitData();
    }
  };

  const submitData = async () => {
    const formattedDate = date.toISOString().split("T")[0];
    setLoading(true);

    const data = {
      location: selectedLocation,
      num_of_guests: Number(guests),
      event_date: formattedDate,
      start_time: time,
      notes: details,
      event_type: "Catering",
      duration_minutes: Number(duration),
      is_delivery: isDelivery,
      ...(isDelivery ? { delivery_address: deliveryAddress } : {}),

      contacts: [
        {
          first_name: name,
          phone_number: mobile,
          email: email,
        },
      ],
    };

    axios
      .post(`${process.env.REACT_APP_BASE_API_URL}/api/event/`, data, {
        headers: {
          Authorization: process.env.REACT_APP_API_KEY,
        },
      })
      .then((response) => {
        setInterval(() => {
          window.location.reload();
        }, 5000);

        setOpen(true);
        setLoading(false);
        setDisableSubmit(true);
        // setName(null);
        // setEmail(null);
        // setMobile(null);
        // setGuests(null);
        // setDate(null);
        // setTime(null);
        // setDetails(null);
      })
      .catch((error) => {
        console.log(error.response.message);
        setLoading(false);
        error.response.data.message.map((items) => {
          setErrorToast(
            items.event_date
              ? items.event_date
              : items.email
              ? items.email
              : items.delivery_address
              ? items.delivery_address
              : "Something went wrong"
          );
        });
      });
  };

  const formatPhoneNumber = (input) => {
    const digitsOnly = input.replace(/\D/g, "");
    let formatted = "";
    if (digitsOnly.length >= 3) {
      formatted = `(${digitsOnly.slice(0, 3)}`;
      if (digitsOnly.length > 3) {
        formatted += `) ${digitsOnly.slice(3, 6)}`;
        if (digitsOnly.length > 6) {
          formatted += `-${digitsOnly.slice(6, 10)}`;
        }
      }
    } else {
      formatted = digitsOnly;
    }
    return formatted;
  };

  const handleMobileChange = (event) => {
    const input = event.target.value;
    const formatted = formatPhoneNumber(input);
    setFormattedMobile(formatted);
    setMobile(input);
  };

  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  useEffect(() => {
    topFunction();
  }, []);

  const handleWheel = (e) => {
    e.preventDefault();
  };

  const handleKeyDowns = (e) => {
    const invalidKeys = ["ArrowUp", "ArrowDown"];
    if (invalidKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    topFunction();
  }, []);

  const handleInputChange = (e) => {
    const sanitizedValue = e.target.value.replace(/\D/g, "");
    setGuests(sanitizedValue);
  };

  const handleScroll = (e) => {
    if (document.activeElement.tagName === "INPUT") {
      e.preventDefault();
    }
  };

  const handleKeyDown = (e) => {
    if (
      document.activeElement.tagName === "INPUT" &&
      (e.key === "ArrowUp" || e.key === "ArrowDown")
    ) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    document.addEventListener("wheel", handleScroll, { passive: false });
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("wheel", handleScroll);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);


  return (
    <Box className="catering-wrapper">
      <Container maxWidth="lg">
        <Box className="catering-top">
          <h1>Let us Cater for you</h1>
          <p>
            We value adaptability when it comes to your events and gatherings.
            Our catering services range from hands-on assistance to VIP
            offerings. Choose from a selection including finger foods,
            appetizers, stationary platters, hors d'oeuvres, buffet, and plated
            dinners.
          </p>
        </Box>
      </Container>
      <Box className="catering-2-wrapper">
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Box className="left-item">
                <img src={cateringImgVector} alt="" className="vector" />
                <img src={cateringImg} alt="pizza" />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className="right-item">
                <h1>
                  The perfect excuse for a
                  <span>
                    <br /> backyard party
                  </span>
                </h1>
                <Typography variant="body1" color="initial">
                  We cater to parties of all sizes, from intimate backyard
                  gatherings to exclusive venues.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <img src={cateringImgVector2} alt="" className="vector2" />
      </Box>
      <Container maxWidth="lg">
        <ImageList sx={{ width: "100%" }} variant="woven" cols={4} gap={8}>
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                src={`${item.img}?w=161&fit=crop&auto=format`}
                srcSet={`${item.img}?w=161&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Container>

      <Container maxWidth="lg">
        <Box className="catering-2-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <img src={cateringImgVector3} alt="" className="vector3" />
              <Box className="right-item">
                <h1>
                  All you need to bring is your <span>appetite</span>
                </h1>
                <Typography variant="body1" color="initial">
                  We handle every aspect of the party planning, including setup,
                  menu creation, food preparation, dining arrangements, and
                  post-event cleanup. All you need to bring are your guests and
                  a hearty appetite for our delectable offerings.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className="left-item">
                <img src={cateringImg2} alt="pizza" />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Container className="cater-form-wrapper" maxWidth="lg">
        <h1>
          Connect with<span> US</span>
        </h1>
        <img src={cateringImgVector4} alt="" className="vector4" />

        <Box className="cater-form">
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              Is this a delivery or Pickup?
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={isDelivery ? "delivery" : "pickup"}
              onChange={handleDeliveryChange}
            >
              <FormControlLabel
                value="delivery"
                control={<Radio />}
                label="Delivery"
              />
              <FormControlLabel
                value="pickup"
                control={<Radio />}
                label="Pickup"
              />
            </RadioGroup>
          </FormControl>
          {isDelivery === true && (
            <Box className="field-wrapper">
              <label htmlFor="">
                Delivery address <span style={{ color: "red" }}>*</span>
              </label>
              <TextField
                value={deliveryAddress}
                type="text"
                id="outlined-basic"
                label=""
                variant="outlined"
                onChange={(event) => setDeliveryAddress(event.target.value)}
                style={{
                  borderRadius: "5px",
                  border:
                    !deliveryAddress && fieldStatus === true
                      ? "1px solid red"
                      : null,
                }}
              />
            </Box>
          )}

          {locationLoading === true ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box className="field-wrapper">
              <label htmlFor="">
                Location <span style={{ color: "red" }}>*</span>
              </label>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedLocation}
                  onChange={(e) => setSelectedLocation(e.target.value)}
                  style={{
                    borderRadius: "7px",
                    border:
                      !selectedLocation && fieldStatus === true
                        ? "1px solid red"
                        : null,
                  }}
                >
                  {locationData.map((items, i) => {
                    return (
                      <MenuItem key={i} value={items.id}>
                        {items.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          )}

          <Box className="field-wrapper">
            <label htmlFor="">
              Name <span style={{ color: "red" }}>*</span>
            </label>
            <TextField
              value={name}
              type="text"
              id="outlined-basic"
              label=""
              variant="outlined"
              onChange={(event) => setName(event.target.value)}
              style={{
                borderRadius: "5px",
                border: !name && fieldStatus === true ? "1px solid red" : null,
              }}
            />
          </Box>
          <Box className="field-wrapper">
            <label htmlFor="">
              Email Address<span style={{ color: "red" }}>*</span>
            </label>
            <TextField
              type="email"
              value={email}
              id="outlined-basic"
              label=""
              variant="outlined"
              onChange={(event) => setEmail(event.target.value)}
              style={{
                borderRadius: "5px",
                border: !email && fieldStatus === true ? "1px solid red" : null,
              }}
            />
          </Box>
          <Box className="field-wrapper">
            <label htmlFor="">
              Phone Number<span style={{ color: "red" }}>*</span>
            </label>
            <TextField
              type="text"
              value={formattedMobile}
              id="outlined-basic"
              label=""
              variant="outlined"
              onChange={handleMobileChange}
              style={{
                borderRadius: "5px",
                border:
                  !mobile && fieldStatus === true ? "1px solid red" : null,
              }}
            />
          </Box>
          <Box className="field-wrapper">
            <label htmlFor="">
              Number of people attending<span style={{ color: "red" }}>*</span>
            </label>
            <TextField
              type="number"
              id="outlined-basic"
              value={guests}
              label=""
              variant="outlined"
              onChange={handleInputChange}
              // InputProps={{
              //   onWheel: handleWheel,
              //   onKeyDown: handleKeyDowns,
              // }}

              inputProps={{
                pattern: '[0-9]*', // Allow only numeric input
              }}
              style={{
                borderRadius: "5px",
                border:
                  !guests && fieldStatus === true ? "1px solid red" : null,
              }}
            />
          </Box>

          <Box className="field-wrapper">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box className="field-wrapper">
                  <label htmlFor="">
                    Date<span style={{ color: "red" }}>*</span>
                  </label>

                  <DatePicker
                    format="MM-dd-y"
                    onChange={setDate}
                    value={date}
                    className={`datePickers ${
                      !date && fieldStatus === true ? "invalid" : ""
                    }`}
                    minDate={new Date()}
                    maxDate={addYears(new Date(), 1)}
                  />
                </Box>
              </Grid>
              
              <Grid item xs={12} md={3}>
                    <Box className="field-wrapper">
                      <label htmlFor="">
                        Time<span style={{ color: "red" }}>*</span>
                      </label>
                      <FormControl>
                        <Select
                          style={{
                            borderRadius: "5px",
                            border:
                              !time && fieldStatus === true
                                ? "1px solid red"
                                : null,
                          }}
                          onChange={(e) => setTime(e.target.value)}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          {timeSlots.map((items, i) => {
                            return (
                              <MenuItem key={i} value={items.btime}>
                                {items.ftime}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
            </Grid>
          </Box>
          <Box className="field-wrapper">
            <label htmlFor="">Details</label>
            <TextField
              id="outlined-basic"
              label=""
              value={details}
              multiline
              rows={4}
              variant="outlined"
              onChange={(event) => setDetails(event.target.value)}
              style={{
                borderRadius: "5px",
              }}
            />
          </Box>

          <Box className="btn-wrapper">
            <LoadingButton
              variant="outlined"
              onClick={() => handleSubmit()}
              loading={loading}
              disabled={disableSubmit}
            >
              Submit
            </LoadingButton>
          </Box>
        </Box>

        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={isEmptyFields}
          autoHideDuration={5000}
          onClose={() => setIsEmptyFields(false)}
        >
          <Alert
            variant="filled"
            onClose={() => setIsEmptyFields(false)}
            severity="error"
            sx={{ width: "100%" }}
          >
            Please fill out all the fields
          </Alert>
        </Snackbar>

        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={open}
          onClose={() => setOpen(false)}
          key={"top" + "right"}
          autoHideDuration={5000}
        >
          <Alert
            variant="filled"
            onClose={() => setOpen(false)}
            severity="success"
            sx={{ width: "100%" }}
          >
            Your request is submitted. Please check your email for further details
          </Alert>
        </Snackbar>

        {errorToast && (
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={Boolean(errorToast)}
            onClose={() => setErrorToast("")}
          >
            <Alert
              variant="filled"
              onClose={() => setErrorToast("")}
              severity="error"
              sx={{ width: "100%" }}
            >
              {errorToast}
            </Alert>
          </Snackbar>
        )}
      </Container>
    </Box>
  );
};

export default Catering;
