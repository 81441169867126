import React, { useState, useEffect } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import aboutImg from "./assets/about-img.png";
import aboutImg2 from "./assets/about-img-2.png";
import ourValuesImg from "./assets/our-value-img.png";
import teamImage from "./assets/team-image.png";
import chief from "./assets/cheifz.jpg";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import "./AboutUs.scss";
import SayHello from "../../Components/SayHello";

const AboutUs = () => {
  const [expanded, setExpanded] = useState(false);
  const [faqCount, setFaqCount] = useState(0);
  const handleAccordionChange = (num) => {
    setExpanded(!expanded);
    setFaqCount(num);
  };

  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  useEffect(() => {
    topFunction();
  }, []);

  return (
    <>
      <Box className="about-us-wrapper">
        <Container maxWidth="lg">
          <Box className="about-us-top">
            <h1>About Us</h1>
            <p>
              The term "Authentic Bengali food" pertains to the culinary
              traditions indigenous to the Bengal region and renowned for its
              vibrant taste profiles, utilization of fragrant spices, and
              emphasis on fresh produce. Bengali cuisine seamlessly blends a
              wide array of rich vegetarian and non-vegetarian delicacies.
            </p>
          </Box>
        </Container>
        <Container maxWidth="lg">
          <Box className="about-wrapper">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box className="left-item">
                  <img src={aboutImg} alt="pizza" />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className="right-item">
                  <h1>
                    Plants{" "}
                    <span>
                      based <br /> Food
                    </span>
                  </h1>
                  <Typography variant="body1" color="initial">
                    ● Recently, plant-based meals have surged in popularity,
                    attributed to their myriad health advantages and positive
                    impact on the environment. <br />● Our evolving, plant-based
                    dietary preferences encompass a wide range of options. We
                    mix fruits, vegetables, grains, legumes, nuts, and seeds to
                    create a varied and delicious selection of dishes.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>

        <Container maxWidth="lg">
          <Box className="about-quote">
            <h1>
              We believe making <span>delicious </span>
              and nourishing <span>plant-based</span> food will help to create a{" "}
              <span>healthier</span> and <span>happier</span> planet.
            </h1>
          </Box>
        </Container>
        <Container maxWidth="lg">
          <Box className="about-started">
            <Timeline position="alternate">
              <TimelineItem>
                <TimelineOppositeContent
                  className="time-line-date"
                  style={{
                    marginTop: "-10px",
                  }}
                >
                  1991
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    className="timeline-dots"
                    variant="outlined"
                    style={{
                      color: "#2f75e6",
                      border: "4px solid #2f75e6",
                      margin: "0px",
                    }}
                  />
                  <TimelineConnector
                    style={{
                      backgroundColor: "#2F75E6",
                      width: "3px",
                      height: "150px",
                    }}
                  />
                </TimelineSeparator>
                <TimelineContent
                  className="time-line-date"
                  style={{
                    fontSize: "14px",
                    marginTop: "-30px",
                  }}
                >
                  <p className="time-line-text" style={{ margin: 0 }}>
                    How it all <span className="font-libre">Started</span>
                  </p>
                  <p>
                    Mohammed arrived in the USA and <br /> registered in Brookly
                    College as a student.
                  </p>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent
                  className="time-line-date"
                  style={{
                    marginTop: "-10px",
                  }}
                >
                  1994
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    className="timeline-dots"
                    variant="outlined"
                    style={{
                      color: "#2f75e6",
                      border: "4px solid #2f75e6",
                      margin: "0px",
                    }}
                  />
                  <TimelineConnector
                    style={{
                      backgroundColor: "#2F75E6",
                      width: "3px",
                      height: "100px",
                    }}
                  />
                </TimelineSeparator>
                <TimelineContent
                  className="time-line-date"
                  style={{
                    fontSize: "14px",
                    marginTop: "-23px",
                  }}
                >
                  <p>
                    He decided to drop out of <br /> college due to financial
                    issues.
                  </p>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent
                  className="time-line-date"
                  style={{
                    marginTop: "-10px",
                  }}
                >
                  1994
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    className="timeline-dots"
                    variant="outlined"
                    style={{
                      color: "#2f75e6",
                      border: "4px solid #2f75e6",
                      margin: "0px",
                    }}
                  />
                  <TimelineConnector
                    style={{
                      backgroundColor: "#2F75E6",
                      width: "3px",
                      height: "100px",
                    }}
                  />
                </TimelineSeparator>
                <TimelineContent
                  className="time-line-date"
                  style={{
                    fontSize: "14px",
                    marginTop: "-23px",
                  }}
                >
                  <p>
                    He took jobs as a restaurant <br /> attendant and delivery
                    person.
                  </p>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent
                  className="time-line-date"
                  style={{
                    marginTop: "-10px",
                  }}
                >
                  1997
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    className="timeline-dots"
                    variant="outlined"
                    style={{
                      color: "#2f75e6",
                      border: "4px solid #2f75e6",
                      margin: "0px",
                    }}
                  />
                  <TimelineConnector
                    style={{
                      backgroundColor: "#2F75E6",
                      width: "3px",
                      height: "100px",
                    }}
                  />
                </TimelineSeparator>
                <TimelineContent
                  className="time-line-date"
                  style={{
                    fontSize: "14px",
                    marginTop: "-23px",
                  }}
                >
                  <p>
                    Mohammed re-enrolled <br /> as a student at BMCC.{" "}
                  </p>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent
                  className="time-line-date"
                  style={{
                    marginTop: "-10px",
                  }}
                >
                  2001
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    className="timeline-dots"
                    variant="outlined"
                    style={{
                      color: "#2f75e6",
                      border: "4px solid #2f75e6",
                      margin: "0px",
                    }}
                  />
                  <TimelineConnector
                    style={{
                      backgroundColor: "#2F75E6",
                      width: "3px",
                      height: "100px",
                    }}
                  />
                </TimelineSeparator>
                <TimelineContent
                  className="time-line-date"
                  style={{
                    fontSize: "14px",
                    marginTop: "-23px",
                  }}
                >
                  <p>
                    He completed his degree. <br /> He also became a licensed
                    realtor.
                  </p>
                </TimelineContent>
              </TimelineItem>

              <TimelineItem>
                <TimelineOppositeContent
                  className="time-line-date"
                  style={{
                    marginTop: "-10px",
                  }}
                >
                  2009
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    className="timeline-dots"
                    variant="outlined"
                    style={{
                      color: "#2f75e6",
                      border: "4px solid #2f75e6",
                      margin: "0px",
                    }}
                  />
                  <TimelineConnector
                    style={{
                      backgroundColor: "#2F75E6",
                      width: "3px",
                      height: "100px",
                    }}
                  />
                </TimelineSeparator>
                <TimelineContent
                  className="time-line-date"
                  style={{
                    fontSize: "14px",
                    marginTop: "-23px",
                  }}
                >
                  <p>
                    Mohammed started his own restaurant “Neerob”, <br /> a
                    Bengali Restaurant in Starling, Bronx
                  </p>
                </TimelineContent>
              </TimelineItem>

              <TimelineItem>
                <TimelineOppositeContent
                  className="time-line-date"
                  style={{
                    marginTop: "-10px",
                  }}
                >
                  2010
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    className="timeline-dots"
                    variant="outlined"
                    style={{
                      color: "#2f75e6",
                      border: "4px solid #2f75e6",
                      margin: "0px",
                    }}
                  />
                  <TimelineConnector
                    style={{
                      backgroundColor: "#2F75E6",
                      width: "3px",
                      height: "100px",
                    }}
                  />
                </TimelineSeparator>
                <TimelineContent
                  className="time-line-date"
                  style={{
                    fontSize: "14px",
                    marginTop: "-23px",
                  }}
                >
                  <p>
                    <a
                      href=" https://www.timeout.com/newyork/restaurants/neerob"
                      target="_blank"
                    >
                      Neerob was featured in TimeOut
                    </a>
                  </p>
                </TimelineContent>
              </TimelineItem>

              <TimelineItem>
                <TimelineOppositeContent
                  className="time-line-date"
                  style={{
                    marginTop: "-10px",
                  }}
                >
                  2011
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    className="timeline-dots"
                    variant="outlined"
                    style={{
                      color: "#2f75e6",
                      border: "4px solid #2f75e6",
                      margin: "0px",
                    }}
                  />
                  <TimelineConnector
                    style={{
                      backgroundColor: "#2F75E6",
                      width: "3px",
                      height: "100px",
                    }}
                  />
                </TimelineSeparator>
                <TimelineContent
                  className="time-line-date"
                  style={{
                    fontSize: "14px",
                    marginTop: "-23px",
                  }}
                >
                  <p>
                    <a
                      target="_blank"
                      href="https://www.nytimes.com/2011/10/05/dining/reviews/neerob-in-the-bronx-nyc-restaurant-review.html"
                    >
                      Neerob was featured in NYT’s Wine & Dine section.
                    </a>
                  </p>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent
                  className="time-line-date"
                  style={{
                    marginTop: "-10px",
                  }}
                >
                  2013
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    className="timeline-dots"
                    variant="outlined"
                    style={{
                      color: "#2f75e6",
                      border: "4px solid #2f75e6",
                      margin: "0px",
                    }}
                  />
                  <TimelineConnector
                    style={{
                      backgroundColor: "#2F75E6",
                      width: "3px",
                      height: "100px",
                    }}
                  />
                </TimelineSeparator>
                <TimelineContent
                  className="time-line-date"
                  style={{
                    fontSize: "14px",
                    marginTop: "-23px",
                  }}
                >
                  <p>
                    Mohammed hosted <br /> Anthony Boudin in Neerob.
                  </p>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent
                  className="time-line-date"
                  style={{
                    marginTop: "-10px",
                  }}
                >
                  2016
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    className="timeline-dots"
                    variant="outlined"
                    style={{
                      color: "#2f75e6",
                      border: "4px solid #2f75e6",
                      margin: "0px",
                    }}
                  />
                  <TimelineConnector
                    style={{
                      backgroundColor: "#2F75E6",
                      width: "3px",
                      height: "100px",
                    }}
                  />
                </TimelineSeparator>
                <TimelineContent
                  className="time-line-date"
                  style={{
                    fontSize: "14px",
                    marginTop: "-23px",
                  }}
                >
                  <p>
                    Neerob was temporarily <br /> renamed to Packsun.
                  </p>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent
                  className="time-line-date"
                  style={{
                    marginTop: "-10px",
                  }}
                >
                  2017
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    className="timeline-dots"
                    variant="outlined"
                    style={{
                      color: "#2f75e6",
                      border: "4px solid #2f75e6",
                      margin: "0px",
                    }}
                  />
                </TimelineSeparator>
                <TimelineContent
                  className="time-line-date"
                  style={{
                    fontSize: "14px",
                    marginTop: "-23px",
                  }}
                >
                  <p>Restaurant was renamed to Neerob.</p>
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          </Box>
        </Container>
        <Container maxWidth="lg" className="our-values-wrapper">
          <Box className="our-values">
            <h1>Our Values</h1>
          </Box>
          <Grid
            container
            className="our-values-card-wrapper"
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 12 }}
          >
            <Grid item lg={4} xs={12} sm={4}>
              <Box className="value-item">
                <img
                  src={ourValuesImg}
                  className="img-fluid"
                  alt=""
                  srcSet=""
                />
                <h3>
                  Authentic &<span> Quality</span>
                </h3>
                <p>
                  We offer top-quality, authentic cuisine made with fresh
                  ingredients, Bangladeshi herbs and spices, enhancing flavors
                  to their maximum
                </p>
              </Box>
            </Grid>

            <Grid item lg={4} xs={12} sm={4}>
              <Box className="value-item">
                <img src={chief} className="img-fluid" alt="" srcSet="" />
                <h3>
                  Customer <span>Satisfaction </span>
                </h3>
                <p>
                  We consistently strive to surpass culinary and customer
                  service expectations, aiming to bring contentment and joy to
                  our patrons. Their satisfaction fills us with profound pride.
                </p>
              </Box>
            </Grid>

            <Grid item lg={4} xs={12} sm={4}>
              <Box className="value-item">
                <Box className="value-item">
                  <img src={teamImage} className="img-fluid" alt="" srcSet="" />
                  <h3>
                    Teamwork &<span> Respect</span>
                  </h3>
                  <p>
                    Our ability to deliver quality and authentic cuisine is a
                    result of fostering a positive work environment founded on
                    principles of teamwork and respect.
                  </p>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>

        <SayHello />

        {/* <Container maxWidth="lg" className="faq-main-wrapper">
          <Typography
            mt={5}
            variant="h4"
            className="faq-heading-main"
            color="initial"
          >
            Frequent <span>questions</span>
          </Typography>
          <Typography
            variant="body1"
            className="faq-para-main"
            m={2}
            color="initial"
          >
            Everything you need to know about us
          </Typography>
          <Box m={5} className="faq-accordian-wrapper">
            <Accordion
              expanded={expanded ? faqCount === 52 : undefined}
              onChange={() => handleAccordionChange(52)}
              className="faq-accordian"
            >
              <AccordionSummary
                iconbuttonprops={{ disableFocusRipple: true }}
                expanded={expanded ? faqCount === 52 : undefined}
                onClick={() => handleAccordionChange(52)}
                expandIcon={
                  expanded && faqCount === 52 ? <CloseIcon /> : <AddIcon />
                }
                className="faq-accordian-summary"
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="faq-accordian-title">
                  Where are you located?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="faq-accordian-desc">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil
                  ducimus esse veniam non quis. Cupiditate a, explicabo minima
                  in quod reprehenderit, saepe quae expedita tempore voluptatem
                  voluptas. Temporibus vitae deserunt harum incidunt sed!
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded ? faqCount === 53 : undefined}
              onChange={() => handleAccordionChange(53)}
              className="faq-accordian"
            >
              <AccordionSummary
                iconbuttonprops={{ disableFocusRipple: true }}
                expanded={expanded ? faqCount === 53 : undefined}
                onClick={() => handleAccordionChange(53)}
                expandIcon={
                  expanded && faqCount === 53 ? <CloseIcon /> : <AddIcon />
                }
                className="faq-accordian-summary"
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="faq-accordian-title">
                  What are your hours of operation?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="faq-accordian-desc">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Consectetur, sit. Ipsa praesentium temporibus quibusdam illum
                  dolorem dolore fuga beatae, laboriosam voluptates labore esse
                  consequatur, asperiores blanditiis iste, cupiditate maxime.
                  Facilis sequi dicta itaque!
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Container> */}
      </Box>
    </>
  );
};

export default AboutUs;
