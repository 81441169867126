import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./LandingPage.scss";
import {
  Container,
  Box,
  Grid,
  Typography,
  CircularProgress,
} from "@mui/material";
import CustomButton from "../../Components/CustomButton";
import heroImg from "../../assets/LandingPage/hero-img.jpg";
import img1 from "../../assets/LandingPage/1.jpg";
import img2 from "../../assets/LandingPage/2.jpg";
import img3 from "../../assets/LandingPage/3.jpg";
import img4 from "../../assets/LandingPage/4.png";
import { ReactComponent as RightArrowWithCircle } from "../../assets/right-arrow-with-circle.svg";
import { ReactComponent as Calender } from "../../assets/calender.svg";
import { ReactComponent as Sunny } from "../../assets/sunny.svg";
import { ReactComponent as Catering } from "../../assets/catering.svg";
import { ReactComponent as Star } from "../../assets/star.svg";
import { ReactComponent as RightArrow } from "../../assets/right-arrow.svg";
import { ReactComponent as Plant } from "../../assets/plant.svg";
import { ReactComponent as Hotel } from "../../assets/hotel.svg";
import { ReactComponent as Love } from "../../assets/love.svg";
import aboutImg from "../../assets/LandingPage/about.jpg";
import vectorImg from "./assets/Vector.png";
import vectorImg2 from "./assets/Vector2.png";
import vectorImg3 from "./assets/Vector3.png";
import vectorImg4 from "./assets/vector4.png";
import vectorImg5 from "./assets/Vector5.png";
import Rating from "@mui/material/Rating";
import Avatar from "@mui/material/Avatar";
import googleImg from "../../assets/LandingPage/google.png";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import SayHello from "../../Components/SayHello";

const LandingPage = () => {
  const navigate = useNavigate();
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_API_URL;

  useEffect(() => {
    fetchReviews();
  }, []);

  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  useEffect(() => {
    topFunction();
  }, []);

  const fetchReviews = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseUrl}/api/company/reviews/`);
      setReviews(response?.data?.reviews);
    } finally {
      setLoading(false);
    }
  };

  const handleReservationBtn = () => {
    navigate("/reservation");
  };

  return (
    <>
      <Box className="landing-page-wrapper">
        <Box className="hero-sec-wrapper">
          <img src={vectorImg} loading="lazy" alt="" className="vector" />
          <Container maxWidth="lg">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                {/* <p className="hero-left-top-text">
                <span>Open</span> 08:00 am - 10:30 pm
                </p> */}
                <Box className="hero-left">
                  <h1>
                    Restaurant <br />
                    <span>of desi cuisines</span>
                  </h1>

                  <p>
                    Our traditional food is filled with rich flavors, vibrant
                    colors, and a quality range of ingredients from all around
                    Bangladesh.
                  </p>
                  <Box className="hero-btn">
                    <CustomButton
                      value="Reservation"
                      style={{
                        padding: "15px 47px 17px 48px",
                      }}
                      onClick={handleReservationBtn}
                    />
                    <img
                      src={vectorImg2}
                      loading="lazy"
                      alt=""
                      className="vector2"
                    />
                    <img
                      src={vectorImg3}
                      loading="lazy"
                      alt=""
                      className="vector3"
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Box className="hero-right">
                  <img src={heroImg} loading="lazy" alt="pizza" />
                </Box>
              </Grid>
            </Grid>
            <Box className="menu-fixed-options">
              <Typography variant="body1" color="initial">
                MENU
              </Typography>
              <Link to="/our-menu?Popular" style={{ textDecoration: "none" }}>
                <Box className="menu-item">
                  <Calender />
                  <p>Popular</p>
                </Box>
              </Link>
              <Link to="/our-menu?Seasonal" style={{ textDecoration: "none" }}>
                <Box className="menu-item">
                  <Sunny />
                  <p>Seasonal</p>
                </Box>
              </Link>
              <Link to="/catering" style={{ textDecoration: "none" }}>
                <Box className="menu-item">
                  <Catering />
                  <p>Catering</p>
                </Box>
              </Link>
              <Link to="/packages" style={{ textDecoration: "none" }}>
                <Box className="menu-item">
                  <Star />
                  <p>Specials</p>
                </Box>
              </Link>
            </Box>
          </Container>
        </Box>
        <Box className="our-menu-wrapper">
          <img src={vectorImg4} loading="lazy" alt="" className="vector4" />
          <img src={vectorImg5} loading="lazy" alt="" className="vector5" />
          <Container maxWidth="lg">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Box className="our-menu-left">
                  <h1>Our menu</h1>
                  <p>
                    We offer cuisines from everyday staples such as rice, fish,
                    and curry to specialty food Bourtha and Ilish fish..
                  </p>
                  <Box
                    className="right-arrow-with-circle"
                    onClick={() => navigate("/our-menu")}
                  >
                    <RightArrowWithCircle /> <span>View menu</span>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Box className="our-menu-right">
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={6}>
                      <Box className="our-menu-right-item">
                        <img loading="lazy" src={img1} alt="pizza" />
                        <Link
                          to="/our-menu?Popular"
                          style={{
                            textDecoration: "none",
                            cursor: "pointer",
                            marginTop:"20px"
                          }}
                        >
                          <h2>
                            Popular
                            <span >
                              <RightArrow style={{height:"22px"}}  />
                            </span>
                          </h2>
                        </Link>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box className="our-menu-right-item margin-top-70">
                        <img src={img2} loading="lazy" alt="pizza" />
                        <Link
                          to="/our-menu?Seasonal"
                          style={{
                            textDecoration: "none",
                            cursor: "pointer",
                            marginTop:"20px"
                          }}
                        >
                          <h2>
                            Seasonal
                            <span>
                              <RightArrow style={{height:"22px"}} />
                            </span>
                          </h2>
                        </Link>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box className="our-menu-right-item">
                        <img src={img3} loading="lazy" alt="pizza" />
                        <Link
                          to="/catering"
                          style={{
                            textDecoration: "none",
                            cursor: "pointer",
                            marginTop:"10px",
                            marginTop:"20px"
                          }}
                        >
                          <h2>
                            Catering
                            <span>
                              <RightArrow style={{height:"22px"}} />
                            </span>
                          </h2>
                        </Link>{" "}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box className="our-menu-right-item margin-top-70">
                        <img src={img4} loading="lazy" alt="pizza" />
                        <Link
                          to="/packages"
                          style={{
                            textDecoration: "none",
                            cursor: "pointer",
                            marginTop:"20px"
                          }}
                        >
                          <h2>
                            Specials
                            <span>
                              <RightArrow style={{height:"22px"}} />
                            </span>
                          </h2>
                        </Link>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>

        {/* <SayHello /> */}

        <Box className="love-us-wrapper" style={{ marginTop: "30px" }}>
          <Container maxWidth="lg">
            <Box className="love-us-box">
              <h1>
                You'll <span>love</span> us
              </h1>
              <Grid container spacing={2} sx={{ my: 5 }}>
                <Grid item xs={12} sm={4}>
                  <Box className="love-us-box-item">
                    <Plant />
                    <h2>Plant Based</h2>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box className="love-us-box-item">
                    <Hotel />
                    <h2>Gluten free</h2>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box className="love-us-box-item">
                    <Love style={{ marginTop: "13px" }} />
                    <h2>Made with love</h2>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>

        <Container maxWidth="lg">
          <Box className="about-wrapper">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box className="left-item">
                  <img src={aboutImg} loading="lazy" alt="pizza" />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className="right-item">
                  <h1>
                    The best <span>vegan comfort food</span>&nbsp; in New York
                  </h1>
                  <Typography variant="body1" color="initial">
                    Experience the essence of plant-based perfection at our
                    restaurant, where we craft the finest vegan cuisine that
                    delights the senses. Discover a delectable world of
                    cruelty-free flavors, created with passion and served with
                    love. Join us on a journey of culinary artistry that
                    celebrates the very best in vegan dining.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>

        <Container maxWidth="lg">
          <Box className="reviews-wrapper">
            <h1>
              <span>What people are</span> saying about us?
            </h1>

            <Box className="reviews-wrapper">
              <Grid container spacing={4}>
                {loading ? (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      minHeight: 200,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress size={32} />
                  </Grid>
                ) : reviews?.length ? (
                  reviews.map((review, i) => (
                    <Grid item xs={12} sm={4} key={i}>
                      <Box className="review-item" sx={{ height: "100%" }}>
                        <Box className="avatar-rating">
                          <span>
                            <Avatar
                              src={review.profile_photo_url}
                              alt={review.author_name}
                            />
                          </span>
                          <span>
                            <Rating
                              name="read-only"
                              value={review.rating}
                              readOnly
                            />
                          </span>
                        </Box>
                        <Box className="person-name">
                          <h2>{review.author_name}</h2>
                        </Box>
                        <Typography variant="body1" color="initial">
                          {review.text}
                        </Typography>
                        <Box className="google-img">
                          <img
                            src={googleImg}
                            loading="lazy"
                            alt=""
                            srcSet=""
                          />
                        </Box>
                      </Box>
                    </Grid>
                  ))
                ) : (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      minHeight: 200,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography textAlign={"center"}>
                      No reviews available.
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default LandingPage;
