import React from "react";

import "./Button.scss";

const CustomButton = ({ value, outline, ...rest }) => {
  return (
    <>
      <button {...rest} className={outline ? "button-outline" : "button-fill"}>
        {value}
      </button>
    </>
  );
};

export default CustomButton;
