import React,{useEffect} from 'react'
import { Box, Container, Grid, Typography } from "@mui/material";

const Terms = () => {

    function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  useEffect(() => {
    topFunction();
  }, []);

  return (
    <div>
      <Box className="schedule-wrapper">
        <Container maxWidth="lg">
          <Box className="schedule-top">
            <h1>Neerob Restaurant Terms and Conditions</h1>

          </Box>
        </Container>
        <Container maxWidth="lg">
          <Grid container>

            <div>
              <p>These terms and conditions (the "Terms") govern your use of the services and facilities provided by Neerob Restaurant ("Neerob," "we," "our," or "us"). By using our services, including but not limited to dining at our restaurant, ordering takeout or delivery, visiting our website, or engaging with our social media accounts, you agree to abide by these Terms. Please read these Terms carefully.</p>
              <h2 style={{ color: "#2f75e6" }}>1. Reservation and Dining</h2>
              <p><strong>1.1. Reservations:</strong> We encourage customers to make reservations in advance. Reservations can be made online, through our mobile app, or by phone. We will make every effort to honor your reservation, but we do not guarantee table availability.</p>
              <p><strong>1.2. Dining:</strong> While dining at Neerob, we expect all patrons to behave respectfully and follow our restaurant policies, including dress code, if applicable.</p>
              <h2 style={{ color: "#2f75e6" }}>2. Orders and Payments</h2>
              <p><strong>2.1. Menu:</strong> We offer a menu with various dishes and beverages. Prices and availability may change without notice.</p>
              <p><strong>2.2. Payment:</strong> Payment methods accepted include cash, credit cards, and any other payment methods we may specify. All payments must be made in the currency indicated on the bill.</p>
              <p><strong>2.3. Gratuities:</strong> Gratuities are at the discretion of the customer. We may include suggested gratuity amounts on the bill.</p>
              <h2 style={{ color: "#2f75e6" }}>3. Cancellations and Refunds</h2>
              <p><strong>3.1. Reservations:</strong> If you need to cancel or modify your reservation, please do so as early as possible. We reserve the right to charge a cancellation fee for no-shows or late cancellations.</p>
              <p><strong>3.2. Orders:</strong> Orders for takeout or delivery may be canceled within a reasonable time frame, subject to our cancellation policy.</p>
              <p><strong>3.3. Refunds:</strong> Refunds will be issued according to our refund policy, which may vary depending on the circumstances.</p>
              <h2 style={{ color: "#2f75e6" }}>4. Health and Safety</h2>
              <p><strong>4.1. Health Guidelines:</strong> We adhere to health and safety guidelines as per local regulations. Patrons must also comply with any health and safety requirements, including mask-wearing and social distancing, as applicable.</p>
              <p><strong>4.2. Food Allergies:</strong> Please inform us of any food allergies or dietary restrictions when placing your order or dining with us. We will do our best to accommodate your needs, but we cannot guarantee allergen-free dishes.</p>
              <h2 style={{ color: "#2f75e6" }}>5. Privacy and Data</h2>
              <p><strong>5.1. Privacy Policy:</strong> Our use of your personal information is governed by our Privacy Policy, which is available on our website.</p>
              <h2 style={{ color: "#2f75e6" }}>6. Intellectual Property</h2>
              <p><strong>6.1. Content:</strong> All content on our website, menus, and promotional materials is the property of Neerob and is protected by copyright and other intellectual property laws.</p>
              <h2 style={{ color: "#2f75e6" }}>7. Amendments and Termination</h2>
              <p><strong>7.1. Changes:</strong> We reserve the right to amend these Terms at any time. Updated Terms will be posted on our website.</p>
              <p><strong>7.2. Termination:</strong> We may terminate your access to our services if you violate these Terms or for any other reason at our discretion.</p>
              <h2 style={{ color: "#2f75e6" }}>8. Contact Us</h2>
              <p>For questions or concerns related to these Terms or any aspect of Neerob Restaurant, please contact us at neerobbronx@gmail.com</p>
              <p style={{ marginBottom: "100px" }}>By using our services, you acknowledge that you have read, understood, and agree to these Terms and Conditions.</p>
            </div>

          </Grid>
        </Container>
      </Box>
    </div>
  )
}

export default Terms