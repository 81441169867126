import MorogPolao from "./Assests/Morog Polao.jpg";
import Chingri_Malai_Curry from "./Assests/Chingri_Malai_Curry.jpg";
import chicken_tikka from "./Assests/chicken_tikka.jpg";
import alochop from "./Assests/alo-chop.webp";
import alogobi from "./Assests/alo-gobi.jpg";
import beefkalabhuna from "./Assests/beef kala bhuna.jpg";
import beeflevercurry from "./Assests/beef lever curry.jpg";
import beefbiryani from "./Assests/beef-biryani.webp";
import beefcurry from "./Assests/beef-curry.jpg";
import beefkachori from "./Assests/beef-kachori.jpg";
import beeftehari from "./Assests/Beef-Tehari.jpg";
import berrysmoothie from "./Assests/berry smoothi.jpg";
import bhapaPitha from "./Assests/bhapa_pitha.jpg";
import borhoni from "./Assests/borhoni.webp";
import brownrice from "./Assests/brown_rice.jpg";
import butterchicken from "./Assests/Butter Chicken.jpg";
import chickenbeharikabab from "./Assests/chciken behari kabab.jpg";
import chickenchaplikabab from "./Assests/chciken chapli kabab.jpg";
import chickensamosa from "./Assests/chciken samosa.jpeg";
import chickensheeshkabab from "./Assests/chciken shesh kabab .jpg";
import chickentihari from "./Assests/chciken tihari.jpg";
import chickenbiryani from "./Assests/chicken biryani.jpg";
import chickenbotikabab from "./Assests/chicken boti kabab.jpg";
import chickenbroat from "./Assests/chicken broast.jpg";
import chickencurry from "./Assests/chicken curry.jpg";
import chickenjalikabab from "./Assests/chicken jali kabab.jpg";
import chickenmalaikabab from "./Assests/chicken malai kabab.jpg";
import chickentandoori from "./Assests/chicken tandoori.jpg";
import dimerchop from "./Assests/dimer chop.webp";
import falooda from "./Assests/falooda.webp";
import friedchickenwingandleg from "./Assests/fried chciken leg and tigh.webp";
import goatbiryani from "./Assests/goat biryani.jpg";
import haleembeef from "./Assests/haleem beef.jpg";
import halim from "./Assests/halim.jpg";
import ilishbhajafried from "./Assests/ilish-bhaja fried.webp";
import illishmacharjhol from "./Assests/illish machar jhol .webp";
import kachhibiryani from "./Assests/Kacchi Biryani.webp";
import kachibiryanigoat from "./Assests/kcchi biryani goat.jpg";
import koimacharkarahi from "./Assests/koi machar curry.webp";
import lambbiryani from "./Assests/Lamb Biryani.jpg";
import lauchingri from "./Assests/lau chingri.jpg";
import magaumacharjhol from "./Assests/magur machar jhol.webp";
import mangolassi from "./Assests/mango lassi.jpg";
import mangosmoothie from "./Assests/mango smoothi.jpg";
import mishtidol from "./Assests/mishti-doi.jpg";
import muttontehari from "./Assests/mutton tehari.jpg";
import nakashi from "./Assests/nikashi.webp";
import palaksag from "./Assests/Palak saag.jpg";
import paratha from "./Assests/paratha_flat_bread.jpg";
import payesh from "./Assests/payesh.jpg";
import pulipitha from "./Assests/puli pitha.webp";
import rasgulla from "./Assests/raasghula.png";
import roimacharjhol from "./Assests/roi machar jhol.jpg";
import saagpanner from "./Assests/saag paneer.jpg";
import shandash from "./Assests/shandash.jpg";
import shrimpbiryani from "./Assests/shrimp biryani.jpg";
import shrimpdopiza from "./Assests/shrimp dopiza.jpeg";
import singmacharfolgobi from "./Assests/sing machar folgobi.jpg";
import smoothi from "./Assests/smoothi.webp";
import tropicalsmoothie from "./Assests/tropical Smoothie.jpg";
import vegepakora from "./Assests/vege pakora.jpg";
import water from "./Assests/water.jpg";
import whiteRice from "./Assests/white_rice.jpg";
import brownRice from "./Assests/brown_rice.jpg";
import vegeBiyarni from "./Assests/vege_biyarni.jpg";
import polao from "./Assests/polao.jpg";
import chanadaal from "./Assests/chana-daal.jpg";
import dalgoat from "./Assests/Dal Goat.jpg";
import alobhaja from "./Assests/aloo-bhaja.jpg";
import alobajawithlong from "./Assests/aloo-baja-with-long-beans.jpg";
import gobiBhaja from "./Assests/gobi_bhaja.jpg";
import mixedVege from "./Assests/mixed_vege.jpg";
import niramish from "./Assests/niramish.jpg";
import bhendiBhaja from "./Assests/bhendi_bhaja.jpg";
import lalSaagBhaja from "./Assests/lal_saag_bhaja.jpg";
import naan from "./Assests/naan.jpg";
import garlicNaan from "./Assests/garlic_naan.jpeg";
import onionNaan from "./Assests/onion_naan.jpg";
import cheeseNaan from "./Assests/cheese_naan.jpg";
import roti from "./Assests/roti.jpeg";
import alooParatha from "./Assests/aloo_paratha.jpg";
import mugaliParatha from "./Assests/mugali_paratha.jpg";
import bhuna_khichuri from "./Assests/bhuna_khichuri.jpg";
import beefJaliKebab from "./Assests/beef jali kabab.jpg";
import shingMacherJhol from "./Assests/shing_macher_jhol.jpg";
import ruiBhaja from "./Assests/rui_bhaja.webp";
import shutkiBhuna from "./Assests/shutki_bhuna.jpg";
import pantaBhat from "./Assests/panta_bhat.jpg";
import dal from "./Assests/dal.jpg";
import channa from "./Assests/channa.jpg";
import mughDal from "./Assests/mugh_dal.jpg";
import squash from "./Assests/squash.jpg";
import lau from "./Assests/lau.jpg";
import greenSalad from "./Assests/special_green_ salad.jpg";
import burger from "./Assests/burger.jpg";
import kati from "./Assests/kati_roll.jpg";
import quesadilla from "./Assests/quesadilla.jpg";
import tomatoBhorta from "./Assests/tomato_bhorta.jpg";
import masoor from "./Assests/masoor.jpg";
import eggplantBhorta from "./Assests/eggplant_bhorta.webp";
import alooBhorta from "./Assests/aloo_bhorta.jpg";
import fishBhorta from "./Assests/fish_bhorta.jpg";
import lotiyaBhorta from "./Assests/lotiya_bhorta.jpg";
import chinguriBhorta from "./Assests/chingri_bhorta.jpg";
import friedRice from "./Assests/fried_rice.jpg";
import chiliChicken from "./Assests/chili_chicken.jpg";
import chiliBeef from "./Assests/chiliBeef.jpg";
import chiliShrimp from "./Assests/chili_shrimp.jpg";
import chickenManchurian from "./Assests/chicken_manchurian.jpg";
import manchurianFish from "./Assests/manchurian_fish.jpg";
import manchurianShrimp from "./Assests/manchurian_shrimp.jpg";
import manchurianBeef from "./Assests/manchurian_beef.jpg";
import chineseMixedVege from "./Assests/chinese_mixed_vege.jpg";
import chickenThaiSoup from "./Assests/chicken_thai_soup.jpg";
import shrimpThaiSoup from "./Assests/shrimp_thai_soup.jpg";
import tomyom from "./Assests/tom_yom.jpg";
import cornSoup from "./Assests/corn_soup.jpg";
import chickenLolli from "./Assests/chicken_lollipop.jpg";
import wonton from "./Assests/wonton.jpg";
import chowin from "./Assests/chowin.jpg";
import lomein from "./Assests/lomein.jpg";
import chopoti from "./Assests/chopoti.jpg";
import fuchka from "./Assests/fuchka.jpg";
import dalPuri from "./Assests/dal_puri.jpg";
import chickenPatti from "./Assests/chicken_patti.jpg";
import veganSamosa from "./Assests/vegan_samosa.jpg";
import chickenRoll from "./Assests/chicken_roll.jpg";
import vegeRoll from "./Assests/vege_roll.jpg";

const menu = [
  {
    id: "1",
    menuItem: "Popular",
    heading:
      "These are the most popular and common menu items ordered by our customers",
    dishes: [
      {
        name: "White rice",
        description: "A Refined cooked rice that is staple food in Bangladesh.",
        unit: "1 Unit",
        dollar: "2",
        availability: "On Demand",
        images: whiteRice,
      },
      {
        name: "Brown rice",
        description:
          "A whole grain rice that has chewy texture and very nutritious. ",
        unit: "1 Unit",
        dollar: "2",
        availability: "On Demand",
        images: brownRice,
      },
    ],
  },
  {
    id: "2",
    menuItem: "Vegetarian (Vegan)",
    heading: "We offer a variety of vegetarian and vegan options.",
    dishes: [
      {
        name: "White rice",
        description: "A Refined cooked rice that is staple food in Bangladesh.",
        unit: "1 Unit",
        dollar: "2.00",
        availability: "Available",
        images: whiteRice,
      },
      {
        name: "Brown rice",
        description:
          "A whole grain rice that has a chewy texture and is very nutritious.",
        unit: "On Demand",
        dollar: "",
        availability: "On Demand",
        images: brownRice,
      },
      {
        name: "Vegetable Biryani",
        description:
          "Long-grain basmati rice is cooked with a variety of fresh vegetables, fragrant spices, and herbs, resulting in a delicious and aromatic one-pot meal.",
        unit: "24 Oz",
        dollar: "8.00",
        availability: "Available",
        images: vegeBiyarni,
      },
      // {
      //   name: "Panta bhat",
      //   description: "Fermented rice, a tangy and refreshing dish often served in Bangladeshi cuisine.",
      //   unit: "On Demand",
      //   dollar: "",
      //   availability: "On Demand",
      //   images: pakora_vege_fritters,
      // },
      {
        name: "Polao",
        description:
          "A fragrant and aromatic rice dish cooked with spices, often with ingredients like vegetables or meat for added flavor.",
        unit: "24 Oz",
        dollar: "4.00",
        availability: "Available",
        images: polao,
      },
      {
        name: "Chana Dal",
        description:
          "Split chickpeas cooked with spices and herbs, resulting in a flavorful and hearty lentil dish.",
        unit: "16 Oz",
        dollar: "6.00",
        availability: "Available",
        images: chanadaal,
      },
      {
        name: "Dal",
        description:
          "A simple and nutritious dish made from lentils, typically cooked with a variety of spices and seasonings.",
        unit: "16 Oz",
        dollar: "6.00",
        availability: "Available",
        images: dal,
      },
      {
        name: "Allo Bhaja",
        description:
          "Crispy fried potato slices seasoned with spices for a flavorful and crunchy snack or side dish.",
        unit: "16 Oz",
        dollar: "6.00",
        availability: "Available",
        images: alobhaja,
      },
      {
        name: "Allo Bhaja w/ Long Beans",
        description:
          "A combination of crispy fried potato slices and long beans, creating a flavorful and texturally diverse side dish.",
        unit: "16 Oz",
        dollar: "6.00",
        availability: "Available",
        images: alobajawithlong,
      },
      {
        name: "Palak / Palang Saag",
        description:
          "Dish made from spinach or other leafy greens cooked with various spices, resulting in a nutritious and flavorful green vegetable side.",
        unit: "16 Oz",
        dollar: "7.00",
        availability: "Available",
        images: palaksag,
      },
      {
        name: "Saag Paneer",
        description:
          "Classic Indian dish featuring paneer cheese cooked in a creamy spinach-based gravy with a blend of spices.",
        unit: "On Demand",
        dollar: "",
        availability: "On Demand",
        images: saagpanner,
      },
      {
        name: "Aloo Gobi",
        description:
          "Traditional dish made with cauliflower and potatoes, cooked with a mix of spices for a delicious and hearty vegetable side.",
        unit: "On Demand",
        dollar: "",
        availability: "On Demand",
        images: alogobi,
      },
      {
        name: "Gobi Bhaja",
        description:
          "Crispy fried cauliflower seasoned with spices, creating a tasty and crunchy side dish.",
        unit: "On Demand",
        dollar: "",
        availability: "On Demand",
        images: gobiBhaja,
      },
      {
        name: "Mix Vege",
        description:
          "Combination of various mixed vegetables cooked with a variety of spices, creating a flavorful and colorful medley of vegetables.",
        unit: "16 Oz",
        dollar: "6.00",
        availability: "Available",
        images: mixedVege,
      },
      {
        name: "Niramish",
        description:
          "Vegetarian dish often free from onions and garlic, prepared using an assortment of vegetables and spices.",
        unit: "16 Oz",
        dollar: "6.00",
        availability: "Available",
        images: niramish,
      },
      {
        name: "Bendi (Okra) Bhaja",
        description:
          "Crispy fried okra (lady's finger) slices seasoned with spices, offering a crunchy and flavorful side dish.",
        unit: "16 Oz",
        dollar: "6.00",
        availability: "Available",
        images: bhendiBhaja,
      },
      {
        name: "Lal Saag Bhaja",
        description:
          "Dish made from red amaranth leaves, cooked with spices to create a delicious and colorful leafy greens side dish.",
        unit: "Seasonal",
        dollar: "",
        availability: "Seasonal",
        images: lalSaagBhaja,
      },
      {
        name: "Naan",
        description:
          "Soft and leavened Indian bread, perfect for scooping up curries and other dishes.",
        unit: "1 Piece",
        dollar: "2.00",
        availability: "Available",
        images: naan,
      },
      {
        name: "Garlic Naan",
        description:
          "Naan bread infused with the rich flavor of garlic, offering a fragrant and aromatic twist on the classic naan.",
        unit: "1 Piece",
        dollar: "3.00",
        availability: "Available",
        images: garlicNaan,
      },
      {
        name: "Onion Naan",
        description:
          "Naan bread with diced onions and spices mixed into the dough, creating a savory and flavorful bread.",
        unit: "1 Piece",
        dollar: "3.00",
        availability: "Available",
        images: onionNaan,
      },
      {
        name: "Cheese Naan",
        description:
          "Naan bread stuffed with cheese, offering a delightful combination of soft, cheesy goodness and bread.",
        unit: "1 Piece",
        dollar: "4.00",
        availability: "Available",
        images: cheeseNaan,
      },
      {
        name: "Roti",
        description:
          "Simple, unleavened Indian bread, often served as a staple with various dishes.",
        unit: "1 Piece",
        dollar: "2.00",
        availability: "Available",
        images: roti,
      },
      {
        name: "Paratha",
        description:
          "Flaky and layered Indian flatbread, typically made by folding and rolling the dough with ghee (clarified butter) between the layers.",
        unit: "1 Piece",
        dollar: "2.00",
        availability: "Available",
        images: paratha,
      },
      {
        name: "Aloo Paratha",
        description:
          "Paratha stuffed with a spiced potato filling, creating a delicious and satisfying bread.",
        unit: "1 Piece",
        dollar: "6.00",
        availability: "Available",
        images: alooParatha,
      },
      {
        name: "Muglai Paratha",
        description:
          "Paratha typically stuffed with a mixture of minced meat, spices, and herbs, creating a flavorful and substantial bread.",
        unit: "1 Piece",
        dollar: "8.00",
        availability: "Available",
        images: mugaliParatha,
      },
    ],
  },
  {
    id: "3",
    menuItem: "Non-Vege",
    heading:
      "The rich and flavorful dishes that primarily include meat, fish, and poultry.",
    dishes: [
      {
        name: "Chicken Biryani",
        description:
          "A flavorful and aromatic one-pot rice dish with chicken, cooked with fragrant spices and herbs.",
        unit: "24 Oz",
        dollar: "10.00",
        availability: "Available",
        images: chickenbiryani,
      },
      {
        name: "Morog Polao",
        description:
          "A delightful chicken and rice dish cooked with a blend of spices, creating a fragrant and satisfying meal.",
        unit: "24 Oz",
        dollar: "12.00",
        availability: "Available",
        images: MorogPolao,
      },
      {
        name: "Beef Biryani",
        description:
          "A fragrant and savory one-pot rice dish with beef, prepared with a mix of spices and herbs for a delicious flavor.",
        unit: "24 Oz",
        dollar: "10.00",
        availability: "Available",
        images: beefbiryani,
      },
      {
        name: "Beef Kichuri",
        description:
          "A hearty dish made from beef and rice, cooked with spices to create a flavorful and comforting meal.",
        unit: "24 Oz",
        dollar: "9.00",
        availability: "Available",
        images: beefkachori,
      },
      {
        name: "Bhuna Khichuri",
        description:
          "A flavorful dish made from rice and beef, cooked with spices and herbs, offering a satisfying and hearty meal.",
        unit: "24 Oz",
        dollar: "9.00",
        availability: "Available",
        images: bhuna_khichuri,
      },
      {
        name: "Goat Biryani",
        description:
          "An aromatic and delicious one-pot rice dish with goat meat, cooked with a variety of spices and herbs.",
        unit: "24 Oz",
        dollar: "12.00",
        availability: "Available",
        images: goatbiryani,
      },
      {
        name: "Kacchi Biryani (Beef)",
        description:
          "A traditional and flavorful beef biryani cooked with marinated meat, fragrant spices, and aromatic rice.",
        unit: "24 Oz",
        dollar: "12.00",
        availability: "Available",
        images: kachhibiryani,
      },
      {
        name: "Kacchi Biryani (Goat)",
        description:
          "A classic goat biryani cooked with marinated meat, fragrant spices, and aromatic rice, resulting in a delicious meal.",
        unit: "24 Oz",
        dollar: "14.00",
        availability: "Available",
        images: kachibiryanigoat,
      },
      {
        name: "Lamb Biryani",
        description:
          "An aromatic one-pot rice dish with tender lamb, cooked with fragrant spices and herbs for a delicious and satisfying meal.",
        unit: "On Demand",
        dollar: "",
        availability: "On Demand",
        images: lambbiryani,
      },
      {
        name: "Chicken Roast",
        description:
          "Chicken seasoned with spices and roasted to perfection, offering a flavorful and crispy poultry dish.",
        unit: "1 Piece",
        dollar: "5.00",
        availability: "Available",
        images: chickenbroat,
      },
      {
        name: "Chicken Curry",
        description:
          "Chicken cooked in a rich and flavorful curry sauce, perfect for enjoying with rice or bread.",
        unit: "16 Oz",
        dollar: "10.00",
        availability: "Available",
        images: chickencurry,
      },
      {
        name: "Tikka Masala",
        description:
          "A creamy and flavorful chicken dish cooked with a blend of spices and a tomato-based sauce.",
        unit: "16 Oz",
        dollar: "10.00",
        availability: "Available",
        images: chicken_tikka,
      },
      {
        name: "Butter Chicken",
        description:
          "A popular Indian chicken dish known for its creamy and buttery tomato sauce, often enjoyed with naan or rice.",
        unit: "16 Oz",
        dollar: "10.00",
        availability: "Available",
        images: butterchicken,
      },
      {
        name: "Chicken Tehari",
        description:
          "A spiced rice dish with chicken, often prepared with aromatic spices and saffron, offering a flavorful and fragrant meal.",
        unit: "On Demand",
        dollar: "",
        availability: "On Demand",
        images: chickentihari,
      },
      {
        name: "Beef Curry",
        description:
          "Beef cooked in a rich and aromatic curry sauce, delivering a hearty and flavorful meat dish.",
        unit: "16 Oz",
        dollar: "10.00",
        availability: "Available",
        images: beefcurry,
      },
      {
        name: "Gorur Kolija Bhuna (Beef Liver Curry)",
        description:
          "A spicy and savory curry made with beef liver, seasoned with a variety of spices and herbs.",
        unit: "16 Oz",
        dollar: "12.00",
        availability: "Available",
        images: beeflevercurry,
      },
      {
        name: "Beef Kala Bhuna",
        description:
          "A spicy and rich beef curry cooked with a blend of aromatic spices, offering a flavorful and satisfying dish.",
        unit: "16 Oz",
        dollar: "12.00",
        availability: "Available",
        images: beefkalabhuna,
      },
      {
        name: "Haleem (Beef)",
        description:
          "A thick and hearty stew made from beef and grains, cooked with a variety of spices and herbs, creating a nourishing and flavorful meal.",
        unit: "Seasonal",
        dollar: "",
        availability: "Seasonal",
        images: haleembeef,
      },
      {
        name: "Beef Tehari",
        description:
          "A spiced rice dish with beef, often prepared with aromatic spices and saffron, offering a flavorful and fragrant meal.",
        unit: "24 Oz",
        dollar: "12.00",
        availability: "Available",
        images: beeftehari,
      },
      {
        name: "Dal Goat",
        description:
          "A hearty dish made from goat meat and lentils, often cooked with a variety of spices to create a flavorful and comforting meal.",
        unit: "16 Oz",
        dollar: "10.00",
        availability: "Available",
        images: dalgoat,
      },
      {
        name: "Haleem (Goat)",
        description:
          "A thick and hearty stew made from goat meat and grains, cooked with a variety of spices and herbs, creating a nourishing and flavorful meal.",
        unit: "16 Oz",
        dollar: "12.00",
        availability: "Seasonal",
        images: halim,
      },
      {
        name: "Mutton Tehari",
        description:
          "A spiced rice dish with mutton, often prepared with aromatic spices and saffron, offering a flavorful and fragrant meal.",
        unit: "On Demand",
        dollar: "",
        availability: "On Demand",
        images: muttontehari,
      },
      {
        name: "Chicken Chapli Kabab",
        description:
          "Minced chicken patties seasoned with spices and herbs, pan-fried to create a delicious and savory snack or appetizer.",
        unit: "1 Piece",
        dollar: "3.00",
        availability: "Available",
        images: chickenchaplikabab,
      },
      {
        name: "Chicken Malai Kabab",
        description:
          "Chicken marinated in a creamy and fragrant sauce, skewered and grilled to perfection, offering a tender and flavorful dish.",
        unit: "7 Piece",
        dollar: "10.00",
        availability: "Available",
        images: chickenmalaikabab,
      },
      {
        name: "Chicken Tandoori",
        description:
          "Chicken marinated in a spicy and tangy yogurt-based sauce, skewered and cooked in a tandoor oven for a smoky and flavorful taste.",
        unit: "1 Piece",
        dollar: "4.00",
        availability: "Available",
        images: chickentandoori,
      },
      {
        name: "Chicken Behari Kebab",
        description:
          "Chicken marinated with a flavorful blend of spices and herbs, skewered and grilled to create a delicious and aromatic dish.",
        unit: "7 Piece",
        dollar: "10.00",
        availability: "Available",
        images: chickenbeharikabab,
      },
      {
        name: "Chicken Boti Kabab",
        description:
          "Cubes of chicken marinated with spices and herbs, skewered and grilled to perfection, resulting in a flavorful and tender kebab.",
        unit: "7 Piece",
        dollar: "10.00",
        availability: "Available",
        images: chickenbotikabab,
      },
      {
        name: "Chicken Shish Kabab",
        description:
          "Chicken pieces marinated with a blend of spices and herbs, skewered and grilled, offering a tasty and satisfying dish.",
        unit: "1 Piece",
        dollar: "3.00",
        availability: "Available",
        images: chickensheeshkabab,
      },
      {
        name: "Chicken Jali Kabab",
        description:
          "Chicken marinated and grilled to create a succulent and flavorful kebab with a distinctive grilled flavor.",
        unit: "1 Piece",
        dollar: "3.00",
        availability: "Available",
        images: chickenjalikabab,
      },
      {
        name: "Beef Jali Kabab",
        description:
          "Beef marinated and grilled to perfection, offering a succulent and flavorful kebab with a distinctive grilled taste.",
        unit: "1 Piece",
        dollar: "3.00",
        availability: "Available",
        images: beefJaliKebab,
      },
    ],
  },
  // rice-based
  {
    id: "12",
    menuItem: "Rice Based",
    heading:
      "Bengali biryani is a flavorful rice dish cooked with marinated meat (often mutton or chicken), fish or Shrimp, fragrant spices, and aromatic basmati rice",
    dishes: [
      {
        name: "White rice",
        description: "A Refined cooked rice that is staple food in Bangladesh.",
        unit: "1 Unit",
        dollar: "2",
        availability: "On Demand",
        images: whiteRice,
      },
      {
        name: "Brown rice",
        description:
          "A whole-grain rice that is chewy and nutritious, often served with wheat bread as a wholesome meal.",
        unit: "1 Unit",
        dollar: "2",
        availability: "On Demand",
        images: brownRice,
      },
      {
        name: "Vegetable Biryani",
        description:
          "Long-grain basmati rice is cooked with a variety of fresh vegetables, fragrant spices, and herbs, resulting in a delicious and aromatic one-pot meal.",
        unit: "1 Unit",
        dollar: "2",
        availability: "On Demand",
        images: vegeBiyarni,
      },
      {
        name: "Panta bhat",
        description:
          "Fermented rice, a tangy and refreshing dish often served in Bangladeshi cuisine.",
        unit: "1 Unit",
        dollar: "2",
        availability: "On Demand",
        images: pantaBhat,
      },
      {
        name: "Polao",
        description:
          "A fragrant and aromatic rice dish cooked with spices, often with ingredients like vegetables or meat for added flavor.",
        unit: "1 Unit",
        dollar: "2",
        availability: "On Demand",
        images: polao,
      },
    ],
  },
  // curry
  {
    id: "11",
    menuItem: "Curry",
    heading:
      "Curries encompass a spectrum of tastes, from the mild and aromatic to the boldly spicy. Typically feature a medley of fresh ingredients, including meat, fish, poultry, or vegetables, cooked in a blend of carefully balanced spices such as mustard oil, garlic, ginger, and turmeric, infusing them with distinct and tantalizing flavors. The curries are usually accompanied by rice or flatbreads and frequently incorporate regional ingredients such as the prized hilsa fish",
    dishes: [
      {
        name: "White rice",
        description: "A Refined cooked rice that is staple food in Bangladesh.",
        unit: "1 Unit",
        dollar: "2",
        availability: "On Demand",
        images: whiteRice,
      },
    ],
  },
  // sea food
  {
    id: "4",
    menuItem: "Seafood",
    heading:
      "We provide a diverse range of delicious and flavorful seafood dishes from the Bengali cuisine, which originates from the Bengal region of the Indian subcontinent, including present-day Bangladesh and West Bengal, India",
    dishes: [
      {
        name: "Shrimp Briyani",
        description:
          "A delectable treat, Shrimp Biryani brings together tender shrimp, fragrant basmati rice, and a medley of aromatic spices.",
        unit: "24 Oz",
        dollar: "15.00",
        availability: "Available",
        images: shrimpbiryani,
      },
      {
        name: "Chingri (Shrimp) Malai Curry",
        description:
          "A rich and creamy curry made with shrimp, cooked in a coconut milk-based sauce, offering a luscious and flavorful seafood dish.",
        unit: "7 Piece",
        dollar: "15.00",
        availability: "Available",
        images: Chingri_Malai_Curry,
      },
      {
        name: "Koi Macher Curry",
        description:
          "A traditional Bengali curry made with climbing perch fish, often cooked with a blend of spices and herbs for a delicious flavor.",
        unit: "On Demand",
        dollar: "",
        availability: "On Demand",
        images: koimacharkarahi,
      },
      {
        name: "Rui Machher Jhol (Fish Curry) ",
        description:
          "A classic Bengali fish curry made with Rohu fish, typically prepared with spices and herbs for a tasty and comforting meal.",
        unit: "1 Piece",
        dollar: "8.00",
        availability: "Available",
        images: roimacharjhol,
      },
      {
        name: "Magur Macher Jhol (Catfish Curry)",
        description:
          "A curry made with catfish, often seasoned with spices and herbs to create a hearty and flavorful fish dish.",
        unit: "1 Piece",
        dollar: "8.00",
        availability: "Available",
        images: magaumacharjhol,
      },
      {
        name: "Illish Macher Jhol (Hilsa Fish Curry)",
        description:
          "A popular Bengali fish curry made with Hilsa fish, often cooked with traditional spices to create a delicious and aromatic dish.",
        unit: "1 Piece",
        dollar: "12.00",
        availability: "Available",
        images: illishmacharjhol,
      },
      {
        name: "Shing Macher Jhol (Stinging Catfish Curry)",
        description:
          "A curry made with stinging catfish, typically cooked with a variety of spices for a flavorful and satisfying fish dish.",
        unit: "2 Piece",
        dollar: "8.00",
        availability: "Available",
        images: shingMacherJhol,
      },
      {
        name: "Shing Mach W/ Fulkopi (Cauliflower W/ Stinging Catfish)",
        description:
          "A dish that combines stinging catfish and cauliflower, cooked with a blend of spices for a flavorful and texturally diverse meal.",
        unit: "",
        dollar: "",
        availability: "On Demand",
        images: singmacharfolgobi,
      },
      {
        name: "Lau Chingri  (Bottle Gourd with Shrimp)",
        description:
          "A dish made with shrimp and bottle gourd, often cooked with spices and herbs for a flavorful and nutritious combination.",
        unit: "24 Oz",
        dollar: "10.00",
        availability: "Available",
        images: lauchingri,
      },
      {
        name: "Ilish Bhaja  (Fried Hilsa Fish)",
        description:
          "Hilsa fish slices seasoned with spices and deep-fried to create a crispy and flavorful seafood snack or side dish.",
        unit: "1 Piece",
        dollar: "12.00",
        availability: "Available",
        images: ilishbhajafried,
      },
      {
        name: "Rui Bhaja",
        description:
          "Rohu fish slices seasoned with spices and deep-fried to create a crispy and flavorful fish snack or side dish.",
        unit: "1 Piece",
        dollar: "8.00",
        availability: "Available",
        images: ruiBhaja,
      },
      {
        name: "Shutki Bhuna (Dried Fish Curry)",
        description:
          "A curry made with dried fish, typically cooked with a blend of spices and herbs for a flavorful and aromatic seafood dish.",
        unit: "8 Oz",
        dollar: "10.00",
        availability: "Available",
        images: shutkiBhuna,
      },
      {
        name: "Shrimp Dopiza",
        description:
          "A dish made with shrimp cooked in a spiced tomato-based gravy, offering a flavorful and tangy seafood dish.",
        unit: "7 Piece",
        dollar: "15.00",
        availability: "Available",
        images: shrimpdopiza,
      },
    ],
  },
  //  bortha
  {
    id: "7",
    menuItem: "Bhorta",
    heading:
      "Bhortas, commonly known as bhartas in Bangladesh, are a diverse selection of dishes that encompass a delightful fusion of mashed vegetables, herbs, or fish. These flavorful concoctions are usually enhanced with the distinctive notes of mustard oil, garlic, onions, and red chili peppers.",
    dishes: [
      {
        name: "Tomato Bhorta",
        description:
          "A dish made from mashed or finely chopped tomatoes, often mixed with spices and herbs for a tangy and flavorful condiment or side dish.        ",
        unit: "8 Oz",
        dollar: "10.00",
        availability: "Available",
        images: tomatoBhorta,
      },
      {
        name: "Masoor Dal Bhorta",
        description:
          "A dish made from mashed or finely chopped red lentils (masoor dal), typically seasoned with spices and herbs for a savory and comforting accompaniment.",
        unit: "8 Oz",
        dollar: "10.00",
        availability: "Available",
        images: masoor,
      },
      {
        name: "Begun (Eggplant) Bhorta",
        description:
          "A dish made from mashed or roasted eggplant, seasoned with spices and herbs for a flavorful and smoky side dish.",
        unit: "8 Oz",
        dollar: "10.00",
        availability: "Available",
        images: eggplantBhorta,
      },
      {
        name: "Aloo (Potato) Bhorta",
        description:
          "A dish made from mashed or finely chopped potatoes, often mixed with spices and herbs for a comforting and hearty side dish.",
        unit: "8 Oz",
        dollar: "10.00",
        availability: "Available",
        images: alooBhorta,
      },
      {
        name: "Fish Bhorta",
        description:
          "A dish made from mashed or finely chopped fish, typically seasoned with spices and herbs for a flavorful and protein-rich condiment.",
        unit: "8 Oz",
        dollar: "10.00",
        availability: "Available",
        images: fishBhorta,
      },
      {
        name: "Lotiya Bhorta",
        description:
          "A dish made from mashed or finely chopped lotus stem (lotiya), often mixed with spices and herbs for a unique and flavorful side dish.",
        unit: "8 Oz",
        dollar: "10.00",
        availability: "Available",
        images: lotiyaBhorta,
      },
      {
        name: "Bala Chingri Bhorta",
        description:
          "A dish made from mashed or finely chopped small shrimp (bala chingri), seasoned with spices and herbs for a savory and seafood-flavored accompaniment.",
        unit: "8 Oz",
        dollar: "10.00",
        availability: "Available",
        images: chinguriBhorta,
      },
    ],
  },
  // chinese
  {
    id: "8",
    menuItem: "Indo Chinese",
    heading: "",
    dishes: [
      {
        name: "Fried Rice",
        description:
          "A dish made from cooked rice stir-fried with a variety of ingredients, such as vegetables, meats, and seasonings, creating a flavorful and satisfying meal.",
        unit: "24 Oz",
        dollar: "8.00",
        availability: "Available",
        images: friedRice,
      },
      {
        name: "Chilli Chicken",
        description:
          "Chicken pieces cooked with a spicy and flavorful chili sauce, often served with vegetables for a zesty and aromatic dish.",
        unit: "16 Oz",
        dollar: "12.00",
        availability: "Available",
        images: chiliChicken,
      },
      {
        name: "Chilli Beef",
        description:
          "Beef pieces cooked with a spicy and flavorful chili sauce, typically accompanied by vegetables for a zesty and hearty dish.",
        unit: "16 Oz",
        dollar: "12.00",
        availability: "Available",
        images: chiliBeef,
      },
      {
        name: "Chilli Shrimp",
        description:
          "Shrimp cooked with a spicy and flavorful chili sauce, often served with vegetables for a zesty and seafood-flavored dish.",
        unit: "16 Oz",
        dollar: "15.00",
        availability: "Available",
        images: chiliShrimp,
      },
      {
        name: "Munchrian Chicken",
        description:
          "Chicken pieces cooked in a tangy and spicy Manchurian sauce, offering a flavorful and zesty chicken dish.",
        unit: "16 Oz",
        dollar: "12.00",
        availability: "Available",
        images: chickenManchurian,
      },
      {
        name: "Munchrian Fish",
        description:
          "Fish pieces cooked in a tangy and spicy Manchurian sauce, typically served with vegetables for a zesty and seafood-flavored meal.",
        unit: "16 Oz",
        dollar: "15.00",
        availability: "Available",
        images: manchurianFish,
      },
      {
        name: "Munchrian Shrimp",
        description:
          "Shrimp cooked in a tangy and spicy Manchurian sauce, often served with vegetables for a zesty and seafood-flavored dish.",
        unit: "16 Oz",
        dollar: "15.00",
        availability: "Available",
        images: manchurianShrimp,
      },
      {
        name: "Munchrian Beef",
        description:
          "Beef pieces cooked in a tangy and spicy Manchurian sauce, typically accompanied by vegetables for a zesty and hearty meal.",
        unit: "16 Oz",
        dollar: "12.00",
        availability: "Available",
        images: manchurianBeef,
      },
      {
        name: "Chinese Mix Veges",
        description:
          "A dish made from a mix of various Chinese-style vegetables, often stir-fried with a savory sauce for a flavorful and colorful side dish.",
        unit: "16 Oz",
        dollar: "10.00",
        availability: "Available",
        images: chineseMixedVege,
      },
      {
        name: "Thai Soup - Chicken",
        description:
          "A chicken-based Thai soup typically prepared with a blend of Thai herbs, spices, and coconut milk for a rich and aromatic broth.",
        unit: "16 Oz",
        dollar: "10.00",
        availability: "Available",
        images: chickenThaiSoup,
      },
      {
        name: "Thai Soup - Shrimp",
        description:
          "A shrimp-based Thai soup prepared with a blend of Thai herbs, spices, and coconut milk for a flavorful and seafood-flavored broth.",
        unit: "16 Oz",
        dollar: "18.00",
        availability: "Available",
        images: shrimpThaiSoup,
      },
      {
        name: "Tom Yon",
        description:
          "A traditional Thai hot and sour soup often made with shrimp and a mixture of herbs and spices, creating a spicy and tangy delicacy.",
        unit: "16 Oz",
        dollar: "16.00",
        availability: "Available",
        images: tomyom,
      },
      {
        name: "Corn Soup",
        description:
          "A soothing and creamy soup made from sweet corn, typically cooked with seasonings and herbs for a comforting and mildly sweet flavor.        ",
        unit: "16 Oz",
        dollar: "16.00",
        availability: "Available",
        images: cornSoup,
      },
      {
        name: "Chicken Lolipup - Dry Or Sauce",
        description:
          "Chicken drumettes or wings seasoned and fried, often served dry or with a sauce for a tasty and finger-licking appetizer or snack.",
        unit: "6 Piece",
        dollar: "15.00",
        availability: "Available",
        images: chickenLolli,
      },
      {
        name: "Wonton - Chicken Or Shrimp",
        description:
          "Dumplings filled with either chicken or shrimp, typically served in a flavorful broth or as an appetizer.",
        unit: "6 Piece",
        dollar: "10.00",
        availability: "Available",
        images: wonton,
      },
      {
        name: "Chowin - Chicken & Veges Mix",
        description:
          "A stir-fried dish made with chicken, mixed vegetables, and noodles, often seasoned with a savory sauce for a satisfying and tasty meal.",
        unit: "24 Oz",
        dollar: "10.00",
        availability: "Available",
        images: chowin,
      },
      {
        name: "Lowmein",
        description:
          "A Chinese noodle dish typically stir-fried with various ingredients like meat, vegetables, and seasonings, creating a flavorful and satisfying meal.",
        unit: "24 Oz",
        dollar: "10.00",
        availability: "Available",
        images: lomein,
      },
    ],
  },
  // plant based
  {
    id: "5",
    menuItem: "Plant Based",
    heading:
      "We offer a very small selection of dishes that are Plant based for our guests. ",
    dishes: [
      {
        name: "Dal",
        description:
          "A simple and nutritious dish made from lentils, typically cooked with a variety of spices and seasonings.",
        unit: "16 Oz",
        dollar: "6.00",
        availability: "",
        images: dal,
      },
      {
        name: "Chana",
        description:
          "A dish made from chickpeas cooked with spices and herbs, resulting in a flavorful and hearty legume dish.",
        unit: "",
        dollar: "",
        availability: "",
        images: channa,
      },
      {
        name: "Mugh Dal",
        description:
          "A dish made from split green gram lentils, often cooked with a variety of spices for a nutritious and flavorful meal.",
        unit: "",
        dollar: "",
        availability: "",
        images: mughDal,
      },
      {
        name: "Mix Vege",
        description:
          "A combination of various mixed vegetables cooked with a variety of spices, creating a flavorful and colorful medley of vegetables.",
        unit: "16 Oz",
        dollar: "6.00",
        availability: "",
        images: mixedVege,
      },
      {
        name: "Squah",
        description:
          "A vegetable dish made from squash, often prepared with spices and herbs for a nutritious and delicious side.",
        unit: "",
        dollar: "",
        availability: "",
        images: squash,
      },
      {
        name: "Lau (Bottle Gourd)",
        description:
          "A vegetable dish made from bottle gourd, typically cooked with a blend of spices and herbs for a flavorful and nutritious addition.",
        unit: "",
        dollar: "",
        availability: "",
        images: lau,
      },
      {
        name: "Brown Rice (Wheat Bread)",
        description:
          "A whole-grain rice that is chewy and nutritious, often served with wheat bread as a wholesome meal.",
        unit: "1 Unit",
        dollar: "2",
        availability: "",
        images: brownrice,
      },
      {
        name: "Special Green Salad",
        description:
          "A salad made from a variety of fresh greens and vegetables, typically dressed with a special dressing for a refreshing and healthy appetizer or side dish. ",
        unit: "",
        dollar: "",
        availability: "",
        images: greenSalad,
      },
    ],
  },
  {
    id: "6",
    menuItem: "Fusion",
    heading: "We offer a selected menu of fusion dishes.",
    dishes: [
      {
        name: "Beef Burger",
        description:
          "What more need to be said of this delightful cooked beef patty between a bun and accompanied by various toppings such as lettuce, tomatoes, cheese, and ",
        unit: "1 Piece",
        dollar: "8.00",
        availability: "Available",
        images: burger,
      },
      {
        name: "Egg And Cheese (Kati Roll)",
        description:
          "An indulgent street food featuring scrambled egg and melted cheese wraped in paratha.  ",
        unit: "1 Piece",
        dollar: "6.00",
        availability: "Available",
        images: kati,
      },
      {
        name: "Quesadillas",
        description:
          "A Bengali interpretation of a Mexican classic, our version of the dish involves a tortilla generously filled with gooey melted cheese and a choice of meats, vegetables, or beans.           ",
        unit: "1 Piece",
        dollar: "15.00",
        availability: "Available",
        images: quesadilla,
      },
    ],
  },
  {
    id: "9",
    menuItem: "Appetizer",
    heading:
      "Our menu presents a delightful assortment of appetizers that showcase the country's abundant culinary legacy, brimming with diverse and enticing flavors. Traditionally served before the main course, these appetizers are readily available at street food vendors and eateries throughout Bangladesh. Among the beloved Bangladeshi appetizers are singara, samosa, and pakora, each renowned for their popularity and deliciousness.",
    dishes: [
      {
        name: "Bhapa Pitha",
        description:
          "A traditional Bengali rice cake typically made by steaming rice flour filled with jaggery or coconut, resulting in a sweet and satisfying delicacy.",
        size: "On Demand",
        dollar: "3",
        availability: "",
        images: bhapaPitha,
        unit: "1 Peice",
      },
      {
        name: "Nakshi Pitha",
        description:
          "A decorative rice cake, often filled with jaggery or grated coconut, traditionally made during special occasions for its intricate and artistic design.",
        size: "On Demand",
        dollar: "3",
        availability: "",
        images: nakashi,
        unit: "1 Peice",
      },
      {
        name: "Puli Pitha",
        description:
          "A sweet and sour rice cake typically made from rice flour, coconut, and jaggery, often shaped like dumplings and enjoyed as a special treat.",
        size: "On Demand",
        dollar: "3",
        availability: "",
        images: pulipitha,
        unit: "1 Peice",
      },
      {
        name: "Chotpoti",
        description:
          "A flavorful Bengali street food made from a mixture of ingredients like potatoes, chickpeas, and eggs, often seasoned with spices and tamarind for a zesty and savory snack.",
        unit: "6 Piece",
        dollar: "6.00",
        availability: "",
        images: chopoti,
      },
      {
        name: "Fuchka",
        description:
          "Also known as -pani puri or -golgappa, these are hollow, crispy wheat shells filled with a mixture of spicy tamarind water, potatoes, and other ingredients, offering a popular and beloved street food",
        unit: "16 Oz",
        dollar: "6.00",
        availability: "",
        images: fuchka,
      },
      {
        name: "Dimer Chop",
        description:
          "A snack made from boiled and spiced eggs, typically coated with a layer of breadcrumbs and deep-fried to create a crispy and flavorful egg snack.",
        unit: "1 Piece",
        dollar: "2.00",
        availability: "",
        images: dimerchop,
      },
      {
        name: "Mughlai Paratha",
        description:
          "A flaky and layered Indian flatbread, often made by folding and rolling the dough with ghee (clarified butter) between the layers, creating a rich and satisfying bread.",
        unit: "1 Piece",
        dollar: "8.00",
        availability: "",
        images: mugaliParatha,
      },
      {
        name: "Dal Puri",
        description:
          "Deep-fried bread made from wheat flour and lentils, offering a flavorful and crispy snack or appetizer.",
        unit: "1 Piece",
        dollar: "2.00",
        availability: "",
        images: dalPuri,
      },
      {
        name: "Chicken Patti",
        description:
          "A patty made from minced or ground chicken, typically seasoned with spices and herbs, and then fried for a savory and meaty appetizer.",
        unit: "1 Piece",
        dollar: "3.00",
        availability: "",
        images: chickenPatti,
      },
      {
        name: "Vege Samosa",
        description:
          "A popular Indian snack made from a pastry shell filled with a spiced mixture of potatoes, peas, and other vegetables, often deep-fried to create a crispy and flavorful appetizer.",
        unit: "1 Piece",
        dollar: "2.00",
        availability: "",
        images: veganSamosa,
      },
      {
        name: "Chicken Samosa",
        description:
          "Similar to vegetable samosa, but filled with a spiced mixture of minced or ground chicken, creating a savory and meaty snack.",
        unit: "1 Piece",
        dollar: "2.00",
        availability: "",
        images: chickensamosa,
      },
      {
        name: "Vege Pakora",
        description:
          "A snack made from vegetables, often coated in a spiced gram flour batter and deep-fried to create a crunchy and flavorful vegetable appetizer.",
        unit: "2 Piece",
        dollar: "1.00",
        availability: "",
        images: vegepakora,
      },
      {
        name: "Fried Chicken (Legs & Thigh)",
        description:
          "Chicken legs and thighs seasoned, coated with breadcrumbs or batter, and deep-fried to create a crispy and succulent meaty appetizer.",
        unit: "1 Piece",
        dollar: "2.00",
        availability: "",
        images: friedchickenwingandleg,
      },
      {
        name: "Allo Chop",
        description:
          "A snack made from spiced mashed potatoes, often formed into patties and deep-fried for a savory and potato-based appetizer.",
        unit: "1 Piece",
        dollar: "3.00",
        availability: "",
        images: alochop,
      },
      {
        name: "Chicken Roll",
        description:
          "A snack or appetizer made by rolling a tortilla or flatbread around a spiced chicken filling, creating a tasty and portable meal.",
        unit: "1 Piece",
        dollar: "2.00",
        availability: "",
        images: chickenRoll,
      },
      {
        name: "Vege Roll",
        description:
          "Similar to chicken roll, but filled with a spiced mixture of vegetables, offering a flavorful and vegetarian-friendly snack or appetizer.",
        unit: "1 Piece",
        dollar: "2.00",
        availability: "",
        images: vegeRoll,
      },
    ],
  },
  {
    id: "10",
    menuItem: "Dessert & Drinks",
    dishes: [
      {
        name: "Borhani",
        description:
          "Borhani is a tangy and spicy traditional Bangladeshi beverage. Its invigorating blend of flavors, including mint, tamarind, and spices.",
        unit: "7 Oz",
        dollar: "3.00",
        availability: "Available",
        images: borhoni,
      },
      {
        name: "Mango Lassi",
        description:
          "Mango lassi is a delicious Bangladeshi drink that combines the sweetness of ripe mangoes with creamy yogurt.",
        unit: "16 Oz",
        dollar: "5.00",
        availability: "Available",
        images: mangolassi,
      },
      {
        name: "Smoothie",
        description:
          "A blended beverage made by combining various fruits, vegetables, and yogurt.",
        unit: "16 Oz",
        dollar: "7.00",
        availability: "Available",
        images: smoothi,
      },
      {
        name: "Falooda",
        description:
          "Falooda is a chilled dessert beverage and is a delightful combination of sweet rose-flavored syrup, vermicelli noodles, basil seeds, and often includes ice cream or kulfi.",
        unit: "16 Oz",
        dollar: "7.00",
        availability: "Available",
        images: falooda,
      },
      {
        name: "Tropical Smoothie",
        description:
          "A refreshing blended drink that combines the vibrant flavors of tropical fruits like mango, pineapple, and coconut.",
        unit: "16 Oz",
        dollar: "7.00",
        availability: "Available",
        images: tropicalsmoothie,
      },
      {
        name: "Mango Smoothie",
        description:
          "A creamy and refreshing beverage made by blending ripe mangoes with yogurt, milk, or a dairy-free alternative.",
        unit: "16 Oz",
        dollar: "7.00",
        availability: "Available",
        images: mangosmoothie,
      },
      {
        name: "Berry Smoothie (Mix)",
        description: "A healthy beverage made from blend of sesonal berry.  ",
        unit: "16 Oz",
        dollar: "7.00",
        availability: "Available",
        images: berrysmoothie,
      },
      {
        name: "Water",
        description: "Stay refreshed—hydrate yourself.",
        unit: "1 Bottle",
        dollar: "2.00",
        availability: "Available",
        images: water,
      },
      {
        name: "Shandesh",
        description:
          "Shandesh is a traditional Bengali sweet crafted from milk and sugar, renowned for its melt-in-the-mouth texture and delicate sweetness.",
        unit: "1 Piece",
        dollar: "2.00",
        availability: "Available",
        images: shandash,
      },
      {
        name: "Mishti Doi",
        description:
          "Mishti Doi is a luscious Bengali dessert, a creamy yogurt treat infused with the sweetness of caramelized sugar, celebrated for its unique blend of tanginess and indulgence.",
        unit: "7 Oz",
        dollar: "3.00",
        availability: "Available",
        images: mishtidol,
      },
      {
        name: "Payesh",
        description:
          "Payesh is a rice pudding infused with aromatic spices like cardamom and cooked slowly in milk until it reaches a delightful creamy consistency.",
        unit: "8 Oz",
        dollar: "4.00",
        availability: "Available",
        images: payesh,
      },
      {
        name: "Roshogolla",
        description:
          "Roshogolla, an iconic Bengali sweet, consists of spongy cottage cheese balls delicately cooked in a sugar syrup.",
        unit: "1 Piece",
        dollar: "2.00",
        availability: "Available",
        images: rasgulla,
      },
    ],
  },
  {
    id: "13",
    menuItem: "Seasonal",
    heading:
      "As the weather shifts, our taste preferences also undergo a transformation. In our culinary tradition, dishes have historically been crafted in accordance with the availability of locally sourced seasonal ingredients. ",
    dishes: [
      // {
      //   name: "White rice",
      //   description: "A Refined cooked rice that is staple food in Bangladesh.",
      //   unit: "1 Unit",
      //   dollar: "2",
      //   availability: "On Demand",
      //   images:sushi,
      // },
    ],
  },
];

export default menu;
