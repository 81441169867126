import React, {useEffect, useState} from "react";
import "./SayHello.scss";
import {Container, Box, Typography} from "@mui/material";
import axios from "axios";

const SayHello = () => {
    const [company, setCompany] = useState([]);

    const baseUrl = process.env.REACT_APP_BASE_API_URL;
    const headers = {
        Authorization: process.env.REACT_APP_API_KEY,
    };
    const fetchCompany = async () => {
        try {
            await axios
                .get(`${baseUrl}/api/company/`, {headers})
                .then((response) => {
                    let _company = response?.data?.results?.[0];
                    setCompany(_company);
                })
                .catch((error) => {
                    console.log(error.message);
                });
        } finally {
        }
    };

    useEffect(() => {
        fetchCompany().then((r) => {
        });
    }, []);
    return (

        <Container maxWidth="lg">
            <Box className="call-out-box-wrapper">
                <h1>
                    Come say <span>hello</span>
                </h1>
                <Typography
                    variant="subtitle1"
                    className="loc-name"
                    color="initial"
                >
                    {company.address}
                </Typography>
                <Typography variant="body1" className="timings" color="initial">
                    08:00 am - 10:30 pm
                </Typography>
            </Box>
        </Container>
    );
};

export default SayHello;
