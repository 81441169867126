import React from "react";
import { Box } from "@mui/material";

import "./Card.scss";

const Card = ({ day, address }) => {

  return (
    <>
      <Box className="card-wrapper">
        {/* <Box className="card-header">MAR 22</Box> */}
        <Box className="card-content">
          <h1>{day}</h1>
          <p className="address">
            {address}
          </p>
          <p className="time">08:00 am - 10:30 pm</p>
        </Box>
      </Box>
    </>
  );
};

export default Card;
