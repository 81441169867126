import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";
import { Box, Container, Grid, Typography } from "@mui/material";
import { ReactComponent as Facebook } from "../../assets/facebook.svg";
import { ReactComponent as Instagram } from "../../assets/instagram.svg";
import CustomButton from "../CustomButton";

const Footer = () => {
  return (
    <>
      <Box className="footer-wrapper">
        <Container maxWidth="lg">
          <Box className="say-hello">
            <h1>
              Come Say <span>hello</span>
            </h1>
            <Link style={{ textDecoration: "none" }} to="/schedule">
              <CustomButton outline value="View weekly schedule" />
            </Link>
          </Box>
          <Box className="footer-sections">
            <Grid container sx={{ textAlign: { xs: "center", md: "left" } }}>
              <Grid item xs={12} md={6}>
                <Box className="social-icons" sx={{ mb: 3 }}>
                  <a
                    href="https://www.facebook.com/neerobrestaurant/"
                    target="_blank"
                  >
                    <Facebook style={{ marginRight: "10px" }} />
                  </a>
                  <a
                    href="https://www.instagram.com/neerob2096/?hl=en"
                    target="_blank"
                  >
                    <Instagram style={{ marginRight: "10px" }} />
                  </a>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={2}
                sx={{ textAlign: { xs: "center", md: "left" } }}
              >
                <Box className="menu">
                  <Link
                    to="/our-menu"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    Menu
                  </Link>
                </Box>
                <Typography
                  variant="body1"
                  className="footer-opt-list"
                  color="initial"
                >
                  <Link
                    to="/schedule"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    Schedule
                  </Link>
                </Typography>
              </Grid>

              <Grid item xs={12} md={2}>
                <Box className="menu">
                  <Link
                    to="/about-us"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    About
                  </Link>
                </Box>
                <Typography
                  variant="body1"
                  className="footer-opt-list"
                  color="initial"
                >
                  <Link
                    to="/catering"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    Catering
                  </Link>
                </Typography>
              </Grid>

              <Grid item xs={12} md={2}>
                <Box className="menu">
                  <Link
                    to="/Terms-and-conditions"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    Terms & Conditions
                  </Link>
                </Box>
                <Typography
                  variant="body1"
                  className="footer-opt-list"
                  color="initial"
                >
                  <Link
                    to="/PrivacyPolicy"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    Privacy Policy
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="body1"
              color="initial"
              className="footer-copyright"
              sx={{ textAlign: { xs: "center", md: "left" } }}
            >
              © 2023 All rights reserved.
            </Typography>

            <Typography
              variant="body1"
              color="initial"
              className="footer-copyright"
              sx={{ textAlign: { xs: "center", md: "right" } }}
            >
              V1.0.4
            </Typography>
          </div>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
