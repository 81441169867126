/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Container, Grid, CircularProgress, Card } from "@mui/material";
import "./Packages.scss";
import IconButton from "@mui/material/IconButton";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import dummyImage from './assets/demo.png'

const Packages = () => {

  const [packagesLoading, setPackagesLoading] = useState(false);
  const [locationLoading, setLocationLoading] = useState(false);
  const [locationData, setLocationData] = useState([]);
  const [packagesData, setPackageData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const baseUrl = process.env.REACT_APP_BASE_API_URL;
  const headers = {
    Authorization: process.env.REACT_APP_API_KEY,
  };

  useEffect(() => {
    getLocation();
  }, []);

  useEffect(() => {
    if (locationData.length > 0) {
      const storedLocation = localStorage.getItem("selectedLocation");
      setSelectedLocation(storedLocation || locationData[0].id);
    }
  }, [locationData]);

  useEffect(() => {
    if (selectedLocation) {
      localStorage.setItem("selectedLocation", selectedLocation);
      getPackages();
    }
  }, [selectedLocation]);

  const getLocation = () => {
    setLocationLoading(true);
    axios.get(`${baseUrl}/api/company/location/`, { headers })
      .then((res) => {
        setLocationLoading(false);
        setLocationData(res.data.results);
      })
      .catch((err) => {
        console.log(err);
        setLocationLoading(false);
      });
  };

  const getPackages = () => {
    setPackagesLoading(true);
    axios
      .get(`${baseUrl}/api/company/package/?location=${selectedLocation}`, {
        headers,
      })
      .then((res) => {
        const activePackages = res.data.results.filter(
          (pkg) => pkg.status === "activated"
        );
        setPackageData(activePackages);
        setPackagesLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setPackagesLoading(false);
      });
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  // workijng on updates

  useEffect(() => {
    topFunction();
  }, []);

  return (
    <>
      <Box className="packages-wrapper">
        <Container maxWidth="lg">
          <Box className="package-top">
            <h1>Our Specials</h1>
            <p>Please select a location:</p>
            <div>
              {locationLoading === true ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "50px",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedLocation}
                    onChange={(e) => setSelectedLocation(e.target.value)}
                    style={{
                      borderRadius: "7px",
                    }}
                  >
                    {locationData.map((items, i) => {
                      return (
                        <MenuItem key={i} value={items.id}>
                          {items.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            </div>
          </Box>
        </Container>

        {packagesLoading === true ? (
          <Container maxWidth="lg">
            <Box
              sx={{
                minHeight: 200,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size={32} />
            </Box>
          </Container>
        ) : (
          <Grid container spacing={3} className="container-size">
            {packagesData.map((items, i) => {
              const firstImage = items?.images[0];
              return (
                <Grid item xs={12} md={3}>
                  <Card
                    key={i}
                    style={{ borderRadius: "10px", padding: "12px" , minHeight:"350px" }}
                  >
                    {firstImage ? (
                      <Slider {...settings}>
                        {items.images.map((img) => {
                          return (
                            <div>
                              <img
                              className="special-package-img"
                                src={img.image}
                                alt="images of packages"
                              />
                            </div>
                          );
                        })}
                      </Slider>
                    ) : (
                      <img src={dummyImage} className="special-package-img" />
                    )}

                    <div className="d-flex" style={{ marginTop: "20px" }}>
                      <p className="special-card-head">{items?.name}</p>
                    </div>
                    <p className="special-card-subhead" style={{ marginTop: items?.description ? "5px" : "40px" }}>{items?.description}</p>
                    <p className="divider" />
                    <IconButton aria-label="settings">
                      <AttachMoneyIcon
                        style={{ fontSize: "20px", color: "#2f75e6" }}
                      />
                    </IconButton>
                    {items?.price}
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Box>
    </>
  );
};

export default Packages;
